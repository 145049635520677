// imporing 3rd party libraries
import useSWR from 'swr'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
//import { PlusCircleFill } from 'react-bootstrap-icons'
import { Container, ListGroup } from 'react-bootstrap'
import { Row, Col, Jumbotron, Breadcrumb } from 'react-bootstrap'
// imporing config and utils files
import { getQueries } from '../../services/'
import { blogsCategory } from '../../data/'
import { paginate } from '../../utils/'
import { endPoints, styles, routes } from '../../config/'
// importing component files
import { PageNotFound } from '../'
import { SearchInput, Count, Loader } from '../../components/'
import { Footer, BlogHolder, Pagination, ListGroups } from '../../components/'

const Blogs = ({ setSearchField }) => {
  const { home } = routes
  const history = useHistory()
  const { blogUrl } = endPoints

  const [pageSize] = useState(3)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedBlog, setSelectedBlog] = useState(null)
  const [searchInputValue, setSearchInputValue] = useState('')

  const { data: allBlogs, error } = useSWR(blogUrl, getQueries)
  if (error) return <PageNotFound text="Failed to get blogs" />
  if (!allBlogs) return <Loader />

  //const { addBlog } = externalLink
  const { marginBottomNegetive /*  cursorPointer  */ } = styles()

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearch = (query) => {
    setSearchInputValue(query)
    setCurrentPage(1)
  }

  const toHomePage = () => {
    history.push(home)
  }

  const handleBlogSelect = (blog) => {
    setSelectedBlog(blog)
    setCurrentPage(1)
  }

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const onNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const filteredBlogs = allBlogs.filter((blog) => {
    if (blog.title.toLowerCase().includes(searchInputValue.toLowerCase())) {
      return true
    }
    return false
  })

  const filtered =
    selectedBlog && selectedBlog.targetAudience
      ? filteredBlogs.filter(
          (blogs) => blogs.targetAudience === selectedBlog.targetAudience,
        )
      : filteredBlogs

  const paginatedBlogs = paginate(filtered, currentPage, pageSize)
  return (
    <>
      <Breadcrumb style={marginBottomNegetive}>
        <Breadcrumb.Item
          onClick={() => {
            toHomePage()
            setSearchField('')
          }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Blogs</Breadcrumb.Item>
      </Breadcrumb>
      <Jumbotron>
        <Container>
          <h1 className="mb-5 text-secondary">Edupreneurs Blog</h1>
          <Count item={filtered} label="blog" />
          <SearchInput
            value={searchInputValue}
            placeholder="search blog articles"
            handleChange={(e) => handleSearch(e.target.value)}
          />
          <Row>
            <Col sm={12} md={3} lg={3}>
              <ListGroups
                valueProperty="id"
                textProperty="name"
                items={blogsCategory}
                selectedItem={selectedBlog}
                onItemSelect={handleBlogSelect}
              />
              <ListGroup className="mt-3">
                <ol>
                  <li style={{ listStyleType: 'none' }}>
                    <ListGroup.Item>capacity building</ListGroup.Item>
                  </li>
                </ol>
              </ListGroup>
              {/* <ListGroup
                className="mt-3"
                style={cursorPointer}
                onClick={() => {
                  openUrl(addBlog)
                }}
              >
                <ol>
                  <li style={{ listStyleType: 'none' }}>
                    <ListGroup.Item>
                      <PlusCircleFill className="mr-3" />
                      add your blog article
                    </ListGroup.Item>
                  </li>
                </ol>
              </ListGroup> */}
            </Col>
            <Col sm={12} md={9} lg={9}>
              <>
                {paginatedBlogs.map((blog) => (
                  <BlogHolder key={blog.id} blog={blog} />
                ))}
                <Container className="pt-5">
                  <Pagination
                    pageSize={pageSize}
                    onNextPage={onNextPage}
                    currentPage={currentPage}
                    itemsCount={filtered.length}
                    onPageChange={handlePageChange}
                    onPreviousPage={onPreviousPage}
                  />
                </Container>
              </>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Footer />
    </>
  )
}

export default Blogs
