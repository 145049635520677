// imporing 3rd party library
import { Container } from "react-bootstrap";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
// imporing config files
import { styles, routes } from "../../config/";
import { ColorContext } from "../../context";

function ParagraphButton({ blogId }) {
  const history = useHistory();
  const { blogsById } = routes;
  const { primary } = useContext(ColorContext);
  const { cusorPointerWithColorPrimary } = styles(primary);
  return (
    <Container>
      <p
        className="mt-2 pt-1"
        onClick={() => history.push(blogsById(blogId))}
        style={cusorPointerWithColorPrimary}
      >
        Read More
      </p>
    </Container>
  );
}

export default ParagraphButton;
