// importing 3rd party library
import React from "react";
// importing component files
import { Jumbotron, BodyTags } from "../components/";
import { JumbotronFluid, CardDecks, Footer } from "../components/";

export default function LandingPage({
  setSearchField,
  setFieldLabel,
  searchField,
  fieldLabel,
}) {
  return (
    <>
      <Jumbotron
        fieldLabel={fieldLabel}
        searchField={searchField}
        setFieldLabel={setFieldLabel}
        setSearchField={setSearchField}
      />
      <BodyTags />
      <JumbotronFluid />
      <CardDecks setSearchField={setSearchField} />
      <Footer />
    </>
  );
}
