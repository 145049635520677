// imporing 3rd party library
import { Img } from "react-image";
import Tooltip from "react-tooltip-lite";
import { useWindowScroll } from "react-use";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CoverPic from "../../assets/Images/cover.jpg";

// imporing config files
import { openUrl } from "../../utils";
import { ColorContext } from "../../context";
import { colors, externalLink, routes, styles } from "../../config";
import { useContext } from "react";

const RecommendationButton = () => {
  const { pathname } = useLocation();
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisiblity] = useState(false);
  const { recommendationPaper } = externalLink;
  const { home, blogs, aboutPlatform } = routes;
  const { primary } = useContext(ColorContext);
  const { darkgrey, bright } = colors;
  const { floatingIcon } = styles(primary);

  useEffect(() => {
    if (
      /*  pageYOffset > 400 && */
      pathname === home ||
      pathname === blogs ||
      pathname === aboutPlatform
    ) {
      setVisiblity(true);
    } else {
      setVisiblity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageYOffset]);

  if (!visible) {
    return false;
  }

  /*  const rotate = keyframes`
  ${headShake}
`;
  const BouncyDiv = styled.div`
    animation: infinite 3s ${rotate};
    border-radius: "50%";
  `; */
  return (
    <div
      style={{
        top: "5",
        zIndex: "1",
        bottom: "0",
        borderRadius: "50%",
        position: "fixed",
        boxShadow: `0px 2px 6px ${darkgrey}`,
        backgroundColor: bright,
      }}
      className="p-3 m-4"
    >
      <Tooltip
        style={{ stroke: "gray", fill: "white" }}
        background="white"
        color="black"
        distance={40}
        arrow={true}
        direction="top"
        tagName="span"
        arrowSize={5}
        spacing={0}
        content={
          <span>
            <p
              style={{
                fontSize: "0.67em",
                textAlign: "center",
                width: "9rem",
                height: "-1rem",
              }}
            >
              Recommendation Paper
            </p>
          </span>
        }
      >
        <Img
          width={60}
          height={60}
          alt="recommendations"
          src={CoverPic}
          onClick={() => openUrl(recommendationPaper)}
          style={floatingIcon}
        />
      </Tooltip>
    </div>
  );
};

export default RecommendationButton;
