import axios from "axios";

export const getCombinedQueries = async (url) => {
  try {
    const { data } = await axios.get(url);
    return [
      ...data.companies,
      ...data.educations,
      ...data.organizations,
    ].sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {}
};
