// imporing 3rd party libraries
import { Jumbotron, Container } from "react-bootstrap";

export default function JumbotronFluid() {
  return (
    <Jumbotron fluid>
      <Container>
        <h4 className="text-center">
          Edupreneurs are everyday discoveries, opportunities for learning,
          business and sources of research.
        </h4>
        <p className="text-center">
          A search service for connecting education institutions, companies,
          partners and organisations aiming at making education quality in
          Southern Africa better”
        </p>
      </Container>
    </Jumbotron>
  );
}
