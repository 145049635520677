// importing 3rd party library
import useSWR from "swr";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Container, Jumbotron } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
// importing config, utils & services files
import { getQueries } from "../../services/";
import { CompanyBreadCrump } from "../../data/";
import { styles, endPoints } from "../../config/";
import { openUrl, completeUrl, detectUrl, checkImage } from "../../utils/";
// importing component files
import { PageNotFound } from "../";
import { CarouselImageSlide, LinkButton } from "../../components/";
import { CardLabelHolder, BreadCrump, WebsiteButton } from "../../components/";
import { Footer, IconHolder, Loader, TestimonialCard } from "../../components/";
//importing hook files
import { useSetBreadCrump } from "../../hooks/";
import { ColorContext } from "../../context";

function CompanyPage({ companyId, setSearchField }) {
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { companyByIdUrl, sourceImageUrl } = endPoints;

  const { data: company, error } = useSWR(
    companyByIdUrl(companyId),
    getQueries
  );

  const { data: breadCrumbItem } = useSetBreadCrump(CompanyBreadCrump, company);

  if (error) return <PageNotFound text="Company doesn't exist" />;
  if (!company) return <Loader />;

  const { phoneNumber, customer1, companyDescription } = company;
  const { facilityDescription, teamDescription, videoLink } = company;
  const { picture1, picture2, picture3, picture4, picture5 } = company;
  const { city, country, email, address, website, contactPerson } = company;
  const { logo, providedEducationServices, targetCustomers, name } = company;

  const modifiedVideoLink = detectUrl(videoLink);

  const pictureAndUndefiend =
    !picture1 && !picture2 && !picture3 && !picture4 && !picture5;
  const pictureOrDefined =
    picture1 || picture2 || picture3 || picture4 || picture5;

  const { borderLeftStyle, bgPrimary } = styles(primary);
  const { flexRowAlignCenter, websiteColHolder } = styles();
  const { shadowBgStyle, jumboDivHolder, websiteBgImgStyle } = styles();
  const { colorPrimary, cursorPointer, colorSecondary } = styles(primary);
  const { bgJumboGreyDisovedFullHeight, brightTextWithShadow } = styles();
  const { jumboGreyBg, colorBright, flexRowAlignEnd, preLineText } = styles();
  const { colorPrimaryTransparent, cursorPointerOneThirdHeight } = styles(
    primary
  );

  return (
    <>
      {company ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <div
            style={
              pictureAndUndefiend
                ? jumboGreyBg
                : Object.assign({}, websiteBgImgStyle, {
                    backgroundImage: `url(${checkImage(
                      sourceImageUrl,
                      picture1,
                      picture2,
                      picture3,
                      picture4,
                      picture5
                    )})`,
                  })
            }
          >
            <div style={pictureOrDefined ? shadowBgStyle : {}}>
              <Container className="pt-5" style={{ zIndex: "1" }}>
                <Jumbotron
                  className={pictureOrDefined ? "pt-5 pb-5 mb-0" : "mb-0 pt-5"}
                  style={pictureOrDefined ? bgJumboGreyDisovedFullHeight : {}}
                >
                  <Container>
                    <h1
                      className="mb-5"
                      style={
                        pictureOrDefined ? brightTextWithShadow : colorSecondary
                      }
                    >
                      {name}
                    </h1>
                    <Container>
                      <Row>
                        <Col xs={12} md={6} lg={6} className="mb-2">
                          <div style={websiteColHolder}>
                            <div
                              className="mb-4 pb-2"
                              style={Object.assign({}, flexRowAlignCenter, {
                                flexWrap: "wrap",
                              })}
                            >
                              <h2
                                className="mr-4"
                                style={colorPrimaryTransparent}
                              >
                                Company info
                              </h2>
                              {logo && (
                                <Card.Img
                                  alt="image"
                                  variant="top"
                                  src={sourceImageUrl(logo)}
                                  style={cursorPointerOneThirdHeight}
                                />
                              )}
                            </div>
                            {/* Important code technique */}
                            {/* <>
                              {providedEducationServices?.length && (
                                <CardLabelHolder
                                  label="we provide"
                                  data={providedEducationServices.map(
                                    (el, index) => (
                                      <span className="text-muted" key={index}>
                                        <IconHolder el={el} index={index} />
                                      </span>
                                    )
                                  )}
                                />
                              )}
                            </> */}
                            <>
                              {providedEducationServices &&
                                providedEducationServices.length > 0 && (
                                  <CardLabelHolder
                                    label="we provide"
                                    data={providedEducationServices.map(
                                      (el, index) => (
                                        <span
                                          className="text-muted"
                                          key={index}
                                        >
                                          <IconHolder el={el} index={index} />
                                        </span>
                                      )
                                    )}
                                  />
                                )}
                            </>
                            <>
                              {targetCustomers && targetCustomers.length > 0 && (
                                <div
                                  className="mb-3 pl-3"
                                  style={borderLeftStyle}
                                >
                                  <p className="h6">
                                    <b style={colorPrimaryTransparent}>
                                      {" "}
                                      target customers
                                    </b>
                                  </p>
                                  {targetCustomers.length > 1 && (
                                    <CheckCircleFill
                                      className="mr-2"
                                      style={colorPrimary}
                                    />
                                  )}
                                  {targetCustomers.map((el, index) => (
                                    <span className="text-muted" key={index}>
                                      {el}{" "}
                                      {targetCustomers.length - 1 !== index && (
                                        <CheckCircleFill
                                          className="mr-2"
                                          style={colorPrimary}
                                        />
                                      )}
                                    </span>
                                  ))}{" "}
                                </div>
                              )}
                            </>
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className="mb-2">
                          <div style={websiteColHolder}>
                            <h2
                              className="mb-4 pb-2"
                              style={colorPrimaryTransparent}
                            >
                              {" "}
                              Contact us
                            </h2>
                            {contactPerson && (
                              <CardLabelHolder
                                label="contact person"
                                data={contactPerson}
                              />
                            )}
                            {phoneNumber && (
                              <CardLabelHolder
                                label="mobile number"
                                data={phoneNumber}
                              />
                            )}
                            {(address || city || country) && (
                              <CardLabelHolder
                                label="address"
                                data={`${address}, ${city}, ${country}`}
                              />
                            )}
                            {email && (
                              <CardLabelHolder label="email" data={email} />
                            )}
                            {website && (
                              <CardLabelHolder
                                label="website"
                                data={
                                  <span
                                    className="text-primary"
                                    style={cursorPointer}
                                    onClick={() => {
                                      openUrl(website);
                                    }}
                                  >
                                    {completeUrl(website)}
                                  </span>
                                }
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Container>
                </Jumbotron>
              </Container>
            </div>
          </div>
          {facilityDescription && (
            <div style={bgPrimary}>
              <Container className="text-center">
                <Row style={colorBright} className="mb-3 pt-5">
                  <Col xs={12} md={12} lg={12}>
                    <p className="h5">
                      <b>OUR PRODUCTS AND SERVICES</b>
                    </p>
                  </Col>
                </Row>
                <Row className="pb-5" style={flexRowAlignEnd}>
                  <Col xs={12} md={12} lg={12}>
                    <p style={preLineText}>{facilityDescription}</p>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {(companyDescription ||
            modifiedVideoLink ||
            teamDescription ||
            website) && (
            <Jumbotron>
              <Container>
                <div className="mb-3" style={jumboDivHolder}>
                  <Container>
                    <h2 className="mb-4 pb-2" style={colorPrimaryTransparent}>
                      About us
                    </h2>
                    {companyDescription && (
                      <CardLabelHolder
                        style={preLineText}
                        data={companyDescription}
                        label="company description"
                      />
                    )}
                    {teamDescription && (
                      <CardLabelHolder
                        label="our team"
                        style={preLineText}
                        data={teamDescription}
                      />
                    )}
                    {modifiedVideoLink && (
                      <CardLabelHolder
                        data={
                          <>
                            {modifiedVideoLink.map((link) => (
                              <LinkButton link={link} />
                            ))}
                          </>
                        }
                        label="check our youtube videos "
                      />
                    )}
                    {website && (
                      <CardLabelHolder
                        label="find more about the company"
                        data={<WebsiteButton website={website} />}
                      />
                    )}
                  </Container>
                </div>
              </Container>
            </Jumbotron>
          )}
          <TestimonialCard
            items={company}
            testimonyKey="customer"
            headerLabel="Testimonies"
            firstTestimony={customer1}
          />
          {pictureOrDefined && (
            <CarouselImageSlide
              defaultSize
              imageOne={picture1}
              imageTwo={picture2}
              imageThree={picture3}
              imageFour={picture4}
              imageFive={picture5}
              testimonyOne={customer1}
            />
          )}
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
export default CompanyPage;
