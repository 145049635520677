export const blogsCategory = [
  { id: "All", name: "all articles" },
  {
    id: 1,
    name: "for schools",
    targetAudience: "Schools",
  },
  {
    id: 2,
    name: "for companies",
    targetAudience: "Education companies",
  },
  {
    id: 3,
    name: "for organizations",
    targetAudience: "Civil organizations",
  },
  {
    id: 4,
    name: "for families",
    targetAudience: "Families",
  },
];
