// importing 3rd party library
import { Col, Container, Jumbotron, Row, Image } from "react-bootstrap";
// importing confing and utils files
import { useContext } from "react";
import { styles } from "../config/";
// importing component files
import { Footer } from "../components";
import { ColorContext } from "../context";
// importing image files
import NBII_LOGO from "../assets/Images/NBII.jpg";
import GLOWDOWN_LOGO from "../assets/Images/Glowdom Logo.png";
import EDUIX_LOGO from "../assets/Images/eduix_logo_2000x734_orange.png";
import NAMIBIA_LOGO from "../assets/Images/NBII_co_branding_in_pdf-page-001.jpg";

export default function AboutPlatform() {
  const { primary } = useContext(ColorContext);
  const { fullHeightWithColorDarkDisolved } = styles(primary);
  const { platFormDivHolder, flexRowAlignCenter } = styles(primary);
  const { platformBackgroundStyle, colorPrimary } = styles(primary);
  return (
    <div style={platformBackgroundStyle}>
      <Jumbotron className="mb-0 pt-5" style={fullHeightWithColorDarkDisolved}>
        <Container className="mt-3">
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12} className="mb-2">
                <div style={platFormDivHolder}>
                  <div className="mb-4 pb-2" style={flexRowAlignCenter}>
                    <h2 className="mr-4" style={colorPrimary}>
                      About Edupreneurs
                    </h2>
                  </div>
                  <>
                    In August 2020, the Southern Africa Innovation Support
                    Programme (SAIS) opened a call for “projects to pilot and
                    validate solutions to make Southern African startup
                    ecosystems more resilient and better able to face an
                    uncertain future”. The context of the call was the fact that
                    Covid-19 has revealed flaws in the worldwide innovation and
                    entrepreneurship ecosystem. They challenged applicants to
                    find these flaws and offer solutions to the innovation
                    business ecosystem in the region, so it would become
                    stronger and more resilient against adversities.
                    <br />
                    <br />
                    Promptly, Glowdom and Eduix, supported by the EDUDITRA
                    project in cooperation with Haaga-Helia University of
                    Applied Sciences, started the discussion to create a
                    consortium. In addition, Hasekiel Johannes, the Innovation
                    Officer of Namibia Business Innovation Institute (NBII), was
                    invited to participate in the consortium thanks to the
                    alignment of ideas from a previous partnership.
                    <br />
                    <br />
                    In order to approach the call made by SAIS, we saw the need
                    to develop a digital business ecosystem in Southern Africa
                    able to leverage the edtech infrastructure and education
                    software development in the region. For that purpose, we
                    envisioned three goals:
                    <br />
                    <br />
                    1. TO BUILD A DIGITAL PLATFORM FOR STRATEGIC PARTNERSHIP, in
                    which education institutions and entrepreneurs can:
                    <div className="ml-5">
                      <ul>
                        <li>
                          {" "}
                          collect and process data about potential customers and
                          suppliers across the region;
                        </li>
                        <li>
                          {" "}
                          network with relevant stakeholders (B2B and B2C);
                        </li>
                        <li>
                          {" "}
                          promote their services and products (visibility and
                          marketing).
                        </li>
                      </ul>
                    </div>
                    2. TO INCREASE DIGITAL AND BUSINESS COMPETENCE THROUGH
                    CAPACITY BUILDING. For that, education stakeholders,
                    software developers, and entrepreneurs will be empowered on
                    <div className="ml-5">
                      <ul>
                        <li>Digitizing education processes</li>
                        <li>
                          innovating business towards sustainable economic
                          growth and resilience in challenging times.
                        </li>
                      </ul>
                    </div>
                    3. TO PUBLISH A POLICY PAPER ON BUILDING-UP RESILIENT
                    BUSINESS ECOSYSTEM. We will integrate practical experience
                    with knowledge creation, resulting in a policy paper for
                    supporting Southern Africa governments to - consolidate
                    resilient start-up ecosystems in sectors other than the
                    edtech field.
                    <br />
                    <br />
                    This realization culminated in the project “Edupreneurs:
                    Networking and Empowering Education Entrepreneurs towards a
                    resilient Edtech Ecosystem in Southern Africa”. The project
                    has a duration of 5 months project (1st March – 31st July
                    2021) and it is being implemented in the Southern Africa
                    Development Community by the project consortium coordinated
                    by NUST-NBII with collaboration of Glowdom (Namibia) and
                    Eduix (Finland). During the project duration, the income
                    generating DIGITAL PLATFORM and CAPACITY BUILDING will be
                    offered for free. After the project, the digital platform
                    will be a paid service for business enterprises and
                    education institutions to continue networking, developing
                    business, generating income, and consolidating the digital
                    business ecosystem in the SADC.
                  </>
                  <>
                    <p className="mt-5 mb-0">Project consortium:</p>
                    <Image
                      width={208}
                      height={97}
                      alt="NAMIBIA"
                      variant="top"
                      src={NAMIBIA_LOGO}
                    />
                    <Image
                      alt="NBII"
                      width={208}
                      height={85}
                      variant="top"
                      src={NBII_LOGO}
                    />
                    <Image
                      width={224}
                      height={88}
                      alt="GLOWDOM"
                      variant="top"
                      src={GLOWDOWN_LOGO}
                    />
                    <Image
                      alt="EDUIX"
                      width={152}
                      height={55.78}
                      variant="top"
                      src={EDUIX_LOGO}
                      className="ml-4 mb-4"
                    />
                  </>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </Jumbotron>
      <Footer />
    </div>
  );
}
