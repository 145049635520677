// imporing 3rd party libraries
import { useContext } from "react";
import { Nav, Jumbotron, Container } from "react-bootstrap";
import { Row, Col, Image, Card } from "react-bootstrap";
//import { Rami, Sebolun, johannes } from '../config/index'
// imporing config files
import { openUrl } from "../utils/";
import { styles, externalLink, emails } from "../config/";
// importing image files
import { Linkedin } from "react-bootstrap-icons";
import SAIS_LOGO from "../assets/Images/sais_logo.png";
import { ColorContext } from "../context";

export default function Footer() {
  const { scrapeEmails } = require("html-email-scraper");
  const { linkedInOne, linkedInTwo } = externalLink;
  const { primary } = useContext(ColorContext);
  const { flexRowAlignJustifyCenterWithWrap } = styles();
  const { address_one, address_two, address_three } = emails;
  const { linkedInThree, linkedInFour, linkedInFive } = externalLink;
  const { cursorPointer, footerCursor, cursorDefault } = styles(primary);

  const Rami = scrapeEmails(address_one);
  const Sebulon = scrapeEmails(address_two);
  const Johannes = scrapeEmails(address_three);

  return (
    <Jumbotron fluid className="mb-0">
      <Container>
        <Row>
          <Col xs={12} sm={6} md={3} lg={3}>
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3">
                <p className="h6">Contact the consortium</p>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} sm={6} md={3} lg={3} className="mt-2">
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3"></Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} sm={6} md={3} lg={4} className="mt-2">
            <Nav className="flex-column"></Nav>
          </Col>

          <Col xs={12} sm={6} md={3} lg={2}>
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3">
                <p className="h6">Funded by</p>
              </Nav.Item>{" "}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={3} lg={3}>
            <Card style={footerCursor}>
              <Card.Body style={{ backgroundColor: "#e9ecef" }}>
                <Card.Title>Eduix Ltd.</Card.Title>
                <Card.Text>
                  <Linkedin
                    className="mr-2"
                    style={cursorPointer}
                    onClick={() => openUrl(linkedInThree)}
                  />
                  Rami Heinisuo, CEO
                  <br />
                  {Rami}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} lg={3} className="mt-2">
            <Card style={footerCursor}>
              <Card.Body style={{ backgroundColor: "#e9ecef" }}>
                <Card.Title>Glowdom</Card.Title>
                <Card.Text>
                  <Linkedin
                    className="mr-2"
                    style={cursorPointer}
                    onClick={() => openUrl(linkedInFour)}
                  />
                  Sebulon David, CEO
                  <br />
                  {Sebulon}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} lg={4} className="mt-2">
            <Card style={footerCursor}>
              <Card.Body style={{ backgroundColor: "#e9ecef" }}>
                <Card.Title>NBII</Card.Title>
                <Card.Text>
                  <Linkedin
                    className="mr-2"
                    style={cursorPointer}
                    onClick={() => openUrl(linkedInFive)}
                  />
                  Hasekiel Johannes, Innovation Officer
                  <br />
                  {Johannes}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={6} md={3} lg={2}>
            <Card style={footerCursor}>
              <Card.Body style={{ backgroundColor: "#e9ecef" }}>
                <Card.Text>
                  <Image
                    alt="SAIS"
                    width={200}
                    height={108}
                    variant="top"
                    src={SAIS_LOGO}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row
          className="mt-5 text-center"
          style={flexRowAlignJustifyCenterWithWrap}
        >
          <Col xs={12} sm={12} md={12} lg={12}>
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3">
                <p className="h6">Copyright © Eduix Ltd. 2021</p>
              </Nav.Item>
            </Nav>
          </Col>
          <Nav>
            <Nav.Link
              style={Object.assign(
                {},
                footerCursor,
                flexRowAlignJustifyCenterWithWrap
              )}
            >
              <span className="mr-3" style={{ cursor: "default" }}>
                Developers:
              </span>
              <br />
              <Linkedin
                className="mr-2"
                style={cursorPointer}
                onClick={() => openUrl(linkedInOne)}
              />{" "}
              <small className="mr-3" style={cursorDefault}>
                Jannaten Nayem
              </small>
              <br />
              <Linkedin
                className="mr-2"
                style={cursorPointer}
                onClick={() => openUrl(linkedInTwo)}
              />
              {"  "}
              <small style={cursorDefault}>Masood Ahmadi</small>
            </Nav.Link>
          </Nav>
        </Row>
      </Container>
    </Jumbotron>
  );
}

/*  <Jumbotron fluid className="mb-0">
      <Container>
        <Row>
          <Col xs={12} sm={6} md={3} lg={3}>
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3">
                <p className="h6">Contact the consortium</p>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} sm={6} md={3} lg={3} className="mt-2">
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3"></Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} sm={6} md={3} lg={4} className="mt-2">
            <Nav className="flex-column"></Nav>
          </Col>

          <Col xs={12} sm={6} md={3} lg={2}>
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3">
                <p className="h6">Funded by</p>
              </Nav.Item>{" "}
            </Nav>
          </Col>
        </Row>
        <Row className="flex align-items-start mt-3">
          <Col xs={12} sm={6} md={3} lg={3}>
            <Nav>
              <Nav.Link
                style={footerCursor}
                onClick={() => openUrl(linkedInThree)}
              >
                Eduix Ltd.
                <br />
                <Linkedin
                  className="mr-2"
                  style={cursorPointer}
                  onClick={() => openUrl(linkedInThree)}
                />{" "}
                Rami Heinisuo, CEO
                <br />
                rami.heinisuo@eduix.fi{" "}
              </Nav.Link>
            </Nav>
          </Col>

          <Col xs={12} sm={6} md={3} lg={3}>
            <Nav>
              <Nav.Item className="text-muted"></Nav.Item>
              <Nav.Link
                style={footerCursor}
                onClick={() => openUrl(linkedInFour)}
              >
                Glowdom
                <br />
                <Linkedin
                  className="mr-2"
                  style={cursorPointer}
                  onClick={() => openUrl(linkedInFour)}
                />{" "}
                Sebulon David, CEO
                <br />
                sebulon@glowdom.com
              </Nav.Link>
            </Nav>
          </Col>
          <Col xs={12} sm={6} md={3} lg={4}>
            <Nav>
              <Nav.Item className="text-muted"></Nav.Item>
              <Nav.Link
                style={footerCursor}
                onClick={() => openUrl(linkedInFive)}
              >
                NBII
                <br />
                <Linkedin
                  className="mr-2"
                  style={cursorPointer}
                  onClick={() => openUrl(linkedInFive)}
                />{" "}
                Hasekiel Johannes, Innovation Officer
                <br />
                hnjohannes@nust.na
              </Nav.Link>
            </Nav>
          </Col>
          <Col xs={12} sm={6} md={3} lg={2}>
            <Nav>
              <Image
                alt="SAIS"
                width={200}
                height={108}
                variant="top"
                src={SAIS_LOGO}
              />
              <br />
            </Nav>
          </Col>
        </Row>
        <Row
          className="mt-5 text-center"
          style={flexRowAlignJustifyCenterWithWrap}
        >
          <Col xs={12} sm={12} md={12} lg={12}>
            <Nav className="flex-column">
              <Nav.Item className="text-muted ml-3">
                <p className="h6">Copyright © Eduix Ltd. 2021</p>
              </Nav.Item>
            </Nav>
          </Col>
          <Nav>
            <Nav.Link
              style={Object.assign(
                {},
                footerCursor,
                flexRowAlignJustifyCenterWithWrap
              )}
            >
              <span className="mr-3" style={{ cursor: "default" }}>
                Developers:
              </span>
              <br />
              <Linkedin
                className="mr-2"
                style={cursorPointer}
                onClick={() => openUrl(linkedInOne)}
              />{" "}
              <small className="mr-3" onClick={() => openUrl(linkedInOne)}>
                Jannaten Nayem
              </small>
              <br />
              <Linkedin
                className="mr-2"
                style={cursorPointer}
                onClick={() => openUrl(linkedInTwo)}
              />
              {"  "}
              <small onClick={() => openUrl(linkedInTwo)}>Masood Ahmadi</small>
            </Nav.Link>
          </Nav>
        </Row>
      </Container>
    </Jumbotron> */
