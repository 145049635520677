import { routes } from "../config";
const { blogs, home, companySearch, schoolSearch, organizationSearch } = routes;

export const CompanyBreadCrump = [
  { id: 1, label: "Home", path: home },
  { id: 2, label: "Company Search", path: companySearch },
];

export const SchoolBreadCrump = [
  { id: 1, label: "Home", path: home },
  { id: 2, label: "School Search", path: schoolSearch },
];

export const organizationBreadCrump = [
  { id: 1, label: "Home", path: home },
  { id: 2, label: "Organization Search", path: organizationSearch },
];

export const BlogBreadCrump = [
  { id: 1, label: "Home", path: home },
  { id: 2, label: "Blogs", path: blogs },
];
