// imporing 3rd party library
import { useContext } from "react";
import { ListGroup } from "react-bootstrap";
// imporing config & context files
import { styles } from "../../config/";
import { ColorContext } from "../../context";

function ListGroups({
  valueProperty,
  textProperty,
  onItemSelect,
  selectedItem,
  items,
}) {
  const { primary } = useContext(ColorContext);
  const { listItemStyleWhileNotSelected, listItemStyleWhileSelected } = styles(
    primary
  );
  return (
    <ListGroup as="ul">
      <li style={{ listStyleType: "none" }}>
        {items.map((item) => (
          <ListGroup.Item
            key={item[valueProperty]}
            onClick={() => onItemSelect(item)}
            style={
              item === selectedItem
                ? listItemStyleWhileSelected
                : listItemStyleWhileNotSelected
            }
          >
            {item[textProperty]}
          </ListGroup.Item>
        ))}
      </li>
    </ListGroup>
  );
}

export default ListGroups;
