// imporing 3rd party libraries
import { useContext } from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
// imporing config files
import { styles } from "../config/";
import { getDate } from "../utils/";
// importing component files
import { ParagraphButton } from "./";
import { ColorContext } from "../context";

export default function BlogHolder({ blog }) {
  const { primary } = useContext(ColorContext);
  const { bgColorPrimaryWithColorBright } = styles(primary);
  return (
    <Card className="mb-3">
      <Card.Body style={bgColorPrimaryWithColorBright}>
        <p className="h3">{blog.title}</p>
        <i>
          authored by - {blog.authorName}, {blog.jobPosition},{" "}
          {blog.organization} <br />
          published on {getDate(blog)}
        </i>
        {blog.subtitle && (
          <p className="mb-3 font-weight-lighter">{blog.subtitle}</p>
        )}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>{blog.abstract}</ListGroupItem>
      </ListGroup>
      <ParagraphButton blogId={blog.id} />
    </Card>
  );
}
