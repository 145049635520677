// importing 3rd party library
import { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// importing utils files
import ScrollToTop from "./utils/scrollToTop";
// importing pages files
import { AllFieldEduprenuer, Blogs, PageNotFound } from "./pages";
import { EducationSearch, OrganizationPage, BlogPage } from "./pages";
import { CompanySearch, EducationPage, AboutPlatform } from "./pages";
import { CompanyPage, OrganizationSearch, LandingPage } from "./pages";
import { Navbar, NavigateToTop, RecommendationButton } from "./components";
// importing config
import { routes } from "./config";
import { ColorContextProvider } from "./context";
// importing css styles
import "./App.css";

function App() {
  const [searchField, setSearchField] = useState("");
  const [fieldLabel, setFieldLabel] = useState("all fields");
  const { companySearch, aboutPlatform, organizationSearchById } = routes;
  const { organizationSearch, schoolSearchById, companySearchById } = routes;
  const { home, blogs, notFound, allResults, schoolSearch, blogsById } = routes;
  return (
    <ColorContextProvider>
      <NavigateToTop />
      <RecommendationButton />
      <Navbar setSearchField={setSearchField} />
      <ScrollToTop />
      <Switch>
        {/* Root Route */}
        <Route
          exact
          path={home}
          render={(props) => (
            <LandingPage
              {...props}
              fieldLabel={fieldLabel}
              searchField={searchField}
              setFieldLabel={setFieldLabel}
              setSearchField={setSearchField}
            />
          )}
        />
        {/* All field result Route */}
        <Route
          path={allResults}
          render={(props) => (
            <AllFieldEduprenuer
              {...props}
              searchField={searchField}
              setSearchField={setSearchField}
            />
          )}
        />
        {/*Routes with Params */}
        <Route
          path={schoolSearchById(":schoolId")}
          render={(props) => (
            <EducationPage
              {...props}
              schoolId={props.match.params.schoolId}
              setSearchField={setSearchField}
            />
          )}
        />
        <Route
          path={companySearchById(":companyId")}
          render={(props) => (
            <CompanyPage
              {...props}
              companyId={props.match.params.companyId}
              setSearchField={setSearchField}
            />
          )}
        />
        <Route
          path={organizationSearchById(":organizationId")}
          render={(props) => (
            <OrganizationPage
              {...props}
              organizationId={props.match.params.organizationId}
              setSearchField={setSearchField}
            />
          )}
        />
        <Route
          path={blogsById(":blogId")}
          render={(props) => (
            <BlogPage
              {...props}
              blogId={props.match.params.blogId}
              setSearchField={setSearchField}
            />
          )}
        />
        {/* Search Routes */}
        <Route
          path={schoolSearch}
          render={(props) => (
            <EducationSearch
              {...props}
              searchField={searchField}
              setSearchField={setSearchField}
            />
          )}
        />
        <Route
          path={companySearch}
          render={(props) => (
            <CompanySearch
              {...props}
              searchField={searchField}
              setSearchField={setSearchField}
            />
          )}
        />
        <Route
          path={organizationSearch}
          render={(props) => (
            <OrganizationSearch
              {...props}
              searchField={searchField}
              setSearchField={setSearchField}
            />
          )}
        />
        {/* Blogs Routes */}
        <Route
          path={blogs}
          render={(props) => (
            <Blogs {...props} setSearchField={setSearchField} />
          )}
        />
        {/* General Route */}
        <Route path={aboutPlatform} component={AboutPlatform} />
        <Route
          path={notFound}
          render={(props) => (
            <PageNotFound {...props} text="Page doesn't exist" />
          )}
        />
        <Redirect to={notFound} />
      </Switch>
    </ColorContextProvider>
  );
}

export default App;
