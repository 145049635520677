import { useContext } from "react";
import { styles } from "../../config/";
import { Button, Badge } from "react-bootstrap";
import { openUrl, completeUrl } from "../../utils/";
import { ColorContext } from "../../context";

function WebsiteButton({ website }) {
  const { primary } = useContext(ColorContext);
  const { bgColorPrimaryWithColorBright } = styles(primary);
  return (
    <p>
      <Button
        variant=""
        onClick={() => openUrl(website)}
        style={bgColorPrimaryWithColorBright}
      >
        Visit us -<Badge variant="light">{completeUrl(website)}</Badge>
      </Button>
    </p>
  );
}

export default WebsiteButton;
