import axios from "axios";

export const getCombinedQueriesWithFiltering = async (url) => {
  const { data } = await axios.get(url);
  const urlSearchQuery = url.split("?search=")[1];
  const allResults = [
    ...data.companies,
    ...data.educations,
    ...data.organizations,
  ].sort((a, b) => a.name.localeCompare(b.name));
  const filteredItems =
    allResults &&
    allResults.filter((item) => {
      if (item.name.toLowerCase().includes(urlSearchQuery.toLowerCase())) {
        return true;
      }
      return false;
    });
  return filteredItems.filter((el) => el !== undefined);
};
