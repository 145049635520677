import axios from "axios";

export const getQueriesWithFiltering = async (url) => {
  const { data } = await axios.get(url);
  const urlSearchQuery = url.split("?search=")[1];
  const filteredItems =
    data &&
    data.filter((item) => {
      if (item.name.toLowerCase().includes(urlSearchQuery.toLowerCase())) {
        return true;
      }
      return false;
    });
  return filteredItems.filter((el) => el !== undefined);
};
