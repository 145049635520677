import React from 'react';
import { openUrl } from '../../utils/'
import { Button } from 'react-bootstrap'
function LinkButton({ link }) {
    return (
        <Button
            onClick={() => openUrl(link)}
            className="mr-2"
            variant="link"
            key={link}
        >
            {link}
        </Button>
    );
}

export default LinkButton;