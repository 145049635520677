// imporing 3rd party libraries
import { Carousel } from "react-bootstrap";
// imporing config files
import { styles, endPoints } from "../config";

function CarouselImageSlide({
  imageOne,
  imageTwo,
  defaultSize,
  imageThree,
  imageFour,
  imageFive,
  testimonyOne,
}) {
  const { sourceImageUrl } = endPoints;
  const { caroselImageHeight, carouselSize } = styles();
  return (
    <>
      <Carousel style={!testimonyOne ? carouselSize : {}}>
        {/* {imageOne && (
          <Carousel.Item>
            <img
              alt="attachment  1"
              className="d-block w-100 h-100"
              src={sourceImageUrl(imageOne)}
              style={defaultSize ? {} : caroselImageHeight}
            />
          </Carousel.Item>
        )} */}
        {imageOne && (
          <Carousel.Item style={{ backgroundSize: "cover" }}>
            <img
              alt="attachment 1"
              className="d-block"
              src={sourceImageUrl(imageOne)}
              style={caroselImageHeight}
            />
          </Carousel.Item>
        )}
        {imageTwo && (
          <Carousel.Item>
            <img
              alt="attachment  2"
              className="d-block"
              src={sourceImageUrl(imageTwo)}
              style={caroselImageHeight}
            />
          </Carousel.Item>
        )}
        {imageThree && (
          <Carousel.Item>
            <img
              alt="attachment  3"
              className="d-block"
              src={sourceImageUrl(imageThree)}
              style={caroselImageHeight}
            />
          </Carousel.Item>
        )}
        {imageFour && (
          <Carousel.Item>
            <img
              alt="attachment  4"
              className="d-block"
              src={sourceImageUrl(imageFour)}
              style={caroselImageHeight}
            />
          </Carousel.Item>
        )}
        {imageFive && (
          <Carousel.Item>
            <img
              alt="attachment 5"
              className="d-block"
              src={sourceImageUrl(imageFive)}
              style={caroselImageHeight}
            />
          </Carousel.Item>
        )}
      </Carousel>
    </>
  );
}

export default CarouselImageSlide;
