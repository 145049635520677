import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { XDiamondFill } from "react-bootstrap-icons";
import { Card, Col, CardDeck } from "react-bootstrap";
// imporing config files
import { openUrl, completeUrl } from "../utils";
import { styles, endPoints, routes } from "../config/";
import { IconHolder } from "./";
import { CardLabelHolder, CardImageHolder, CardFooterHolder } from "./";
import { ColorContext } from "../context";

function OrganizationCardDeck({ data }) {
  const history = useHistory();
  const { sourceImageUrl } = endPoints;
  const { organizationSearchById } = routes;
  const { primary } = useContext(ColorContext);
  const { fullHeight, cursorPointer, cardImageStyle } = styles();
  const { cusorPointerWithColorPrimary, marginTopDouble } = styles(primary);

  return (
    <CardDeck>
      {data.map((organization, index) => {
        const { picture1, name, email } = organization;
        const { phoneNumber, contactPerson, id } = organization;
        const { website, providedEducationServices, country } = organization;
        return (
          <Col sm={12} md={6} lg={6} key={index} className="mb-3">
            <Card style={fullHeight}>
              {picture1 === "" ? (
                <CardImageHolder height="17.5rem">
                  <XDiamondFill
                    width={50}
                    height={50}
                    style={cusorPointerWithColorPrimary}
                    onClick={() => history.push(organizationSearchById(id))}
                  />
                </CardImageHolder>
              ) : (
                <Card.Img
                  alt="image"
                  variant="top"
                  style={cardImageStyle}
                  src={sourceImageUrl(picture1)}
                  onClick={() => history.push(organizationSearchById(id))}
                />
              )}
              <Card.Body
                className="text-left"
                style={picture1 === "" ? marginTopDouble : {}}
              >
                <h3 className="mb-4 text-muted">{name}</h3>
                {contactPerson && (
                  <CardLabelHolder
                    data={contactPerson}
                    label="Contact Person : "
                  />
                )}
                {country && (
                  <CardLabelHolder label="Country : " data={country} />
                )}
                {email && <CardLabelHolder label="Email : " data={email} />}
                <CardLabelHolder
                  label="Phone : "
                  data={phoneNumber !== "" ? phoneNumber : "Not Given"}
                />
                {providedEducationServices.length > 0 && (
                  <CardLabelHolder
                    label="We Provide"
                    data={providedEducationServices.map((el, index) => (
                      <span className="text-muted" key={index}>
                        <IconHolder el={el} index={index} />
                      </span>
                    ))}
                  />
                )}
                {website && (
                  <CardLabelHolder
                    label="Website : "
                    data={
                      <span
                        style={cursorPointer}
                        className="text-primary"
                        onClick={() => {
                          openUrl(website);
                        }}
                      >
                        {completeUrl(website)}
                      </span>
                    }
                  />
                )}
              </Card.Body>
              <CardFooterHolder
                data={id}
                history={history}
                route="organization-search"
                label="find more about the organization"
              />
            </Card>
          </Col>
        );
      })}
    </CardDeck>
  );
}
export default OrganizationCardDeck;
