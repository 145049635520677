// importing 3rd party library
import useSWR from "swr";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Container, Jumbotron } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
// importing config, utils & services files
import { getQueries } from "../../services/";
import { styles, endPoints } from "../../config";
import { organizationBreadCrump } from "../../data";
import { openUrl, completeUrl, detectUrl, checkImage } from "../../utils/";
// importing component files
import { PageNotFound } from "../";
import { CarouselImageSlide, WebsiteButton } from "../../components/";
import { CardLabelHolder, BreadCrump, LinkButton } from "../../components/";
import { Footer, IconHolder, Loader, TestimonialCard } from "../../components/";
// importing hook files
import { useSetBreadCrump } from "../../hooks";
import { ColorContext } from "../../context";

function OrganizationPage({ organizationId, setSearchField }) {
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { organizationByIdUrl, sourceImageUrl } = endPoints;

  const { data: organization, error } = useSWR(
    organizationByIdUrl(organizationId),
    getQueries
  );

  const { data: breadCrumbItem } = useSetBreadCrump(
    organizationBreadCrump,
    organization
  );

  if (error) return <PageNotFound text="Organization doesn't exist" />;
  if (!organization) return <Loader />;

  const { logo, type, city, country } = organization;
  const { providedEducationServices, videoLink } = organization;
  const { picture1, picture2, picture3, picture4, picture5 } = organization;
  const { targetAudience, contactPerson, phoneNumber, address } = organization;
  const { missionStatement, educationProposal, partner1, name } = organization;
  const { email, facilityDescription, teamDescription, website } = organization;

  const modifiedVideoLink = detectUrl(videoLink);

  const pictureAndUndefiend =
    !picture1 && !picture2 && !picture3 && !picture4 && !picture5;
  const pictureOrDefined =
    picture1 || picture2 || picture3 || picture4 || picture5;

  const { shadowBgStyle, jumboDivHolder, websiteBgImgStyle } = styles(primary);
  const { bgJumboGreyDisovedFullHeight, brightTextWithShadow } = styles(
    primary
  );
  const { borderLeftStyle } = styles(primary);
  const { colorPrimaryTransparent, bgPrimary } = styles(primary);
  const { flexRowAlignCenter, websiteColHolder } = styles(primary);
  const { cursorPointerOneThirdHeight, colorBright } = styles(primary);
  const { jumboGreyBg, flexRowAlignEnd, preLineText } = styles(primary);
  const { colorPrimary, cursorPointer, colorSecondary } = styles(primary);

  return (
    <>
      {organization ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <div
            style={
              pictureAndUndefiend
                ? jumboGreyBg
                : Object.assign({}, websiteBgImgStyle, {
                    backgroundImage: `url(${checkImage(
                      sourceImageUrl,
                      picture1,
                      picture2,
                      picture3,
                      picture4,
                      picture5
                    )})`,
                  })
            }
          >
            <div style={pictureOrDefined ? shadowBgStyle : {}}>
              <Container className="pt-5" style={{ zIndex: "1" }}>
                <Jumbotron
                  className={pictureOrDefined ? "pt-5 pb-5 mb-0" : "mb-0 pt-5"}
                  style={pictureOrDefined ? bgJumboGreyDisovedFullHeight : {}}
                >
                  <Container>
                    <h1
                      className="mb-5"
                      style={
                        pictureOrDefined ? brightTextWithShadow : colorSecondary
                      }
                    >
                      {name}
                    </h1>
                    <Container>
                      <Row>
                        <Col xs={12} md={6} lg={6} className="mb-2">
                          <div style={websiteColHolder}>
                            <div
                              className="mb-4 pb-2"
                              style={flexRowAlignCenter}
                            >
                              <h2
                                className="mr-4"
                                style={colorPrimaryTransparent}
                              >
                                Organization info
                              </h2>
                              {logo && (
                                <Card.Img
                                  alt="image"
                                  variant="top"
                                  src={sourceImageUrl(logo)}
                                  style={cursorPointerOneThirdHeight}
                                />
                              )}
                            </div>
                            {type && (
                              <CardLabelHolder
                                data={type}
                                label="organization type"
                              />
                            )}
                            {providedEducationServices &&
                              providedEducationServices.length > 0 && (
                                <CardLabelHolder
                                  label="we provide"
                                  data={providedEducationServices.map(
                                    (el, index) => (
                                      <span className="text-muted" key={index}>
                                        <IconHolder el={el} index={index} />
                                      </span>
                                    )
                                  )}
                                />
                              )}
                            {targetAudience && targetAudience.length > 0 && (
                              <div
                                className="mb-3 pl-3"
                                style={borderLeftStyle}
                              >
                                <p className="h6">
                                  <b style={colorPrimaryTransparent}>
                                    {" "}
                                    target audience
                                  </b>
                                </p>
                                {targetAudience.length > 1 && (
                                  <CheckCircleFill
                                    className="mr-2"
                                    style={colorPrimary}
                                  />
                                )}
                                {targetAudience.map((el, index) => (
                                  <span className="text-muted" key={index}>
                                    {el}{" "}
                                    {targetAudience.length - 1 !== index && (
                                      <CheckCircleFill
                                        className="mr-2"
                                        style={colorPrimary}
                                      />
                                    )}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className="mb-2">
                          <div style={websiteColHolder}>
                            <h2
                              className="mb-4 pb-2"
                              style={colorPrimaryTransparent}
                            >
                              {" "}
                              Contact us
                            </h2>
                            {contactPerson && (
                              <CardLabelHolder
                                data={contactPerson}
                                label="contact person"
                              />
                            )}
                            {phoneNumber && (
                              <CardLabelHolder
                                data={phoneNumber}
                                label="mobile number"
                              />
                            )}
                            {(address || city || country) && (
                              <CardLabelHolder
                                label="country"
                                data={`${address}, ${city}, ${country}`}
                              />
                            )}
                            {email && (
                              <CardLabelHolder label="email" data={email} />
                            )}
                            {website && (
                              <CardLabelHolder
                                label="website"
                                data={
                                  <span
                                    className="text-primary"
                                    style={cursorPointer}
                                    onClick={() => openUrl(website)}
                                  >
                                    {" "}
                                    {completeUrl(website)}
                                  </span>
                                }
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Container>
                </Jumbotron>
              </Container>
            </div>
          </div>
          {facilityDescription && (
            <div style={bgPrimary}>
              <Container className="text-center">
                <Row style={colorBright} className="mb-3 pt-5">
                  <Col xs={12} md={12} lg={12}>
                    <p className="h5">
                      <b>OUR PRODUCT AND SERVICES</b>
                    </p>
                  </Col>
                </Row>
                <Row className="pb-5" style={flexRowAlignEnd}>
                  <Col xs={12} md={12} lg={12}>
                    <p style={preLineText}>{facilityDescription}</p>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {(educationProposal ||
            modifiedVideoLink ||
            missionStatement ||
            teamDescription ||
            website) && (
            <Jumbotron>
              <Container>
                <div className="mb-3" style={jumboDivHolder}>
                  <Container>
                    <h2 className="mb-4 pb-2" style={colorPrimaryTransparent}>
                      About us
                    </h2>
                    {teamDescription && (
                      <CardLabelHolder
                        style={preLineText}
                        data={teamDescription}
                        label="Team description "
                      />
                    )}
                    {missionStatement && (
                      <CardLabelHolder
                        label="Our mission"
                        style={preLineText}
                        data={missionStatement}
                      />
                    )}
                    {educationProposal && (
                      <CardLabelHolder
                        style={preLineText}
                        data={educationProposal}
                        label="Organization proposal"
                      />
                    )}
                    {modifiedVideoLink && (
                      <CardLabelHolder
                        data={
                          <>
                            {modifiedVideoLink.map((link) => (
                              <LinkButton link={link} />
                            ))}
                          </>
                        }
                        label="check our youtube videos "
                      />
                    )}
                    {website && (
                      <CardLabelHolder
                        label="find more about the organization"
                        data={<WebsiteButton website={website} />}
                      />
                    )}
                  </Container>
                </div>
              </Container>
            </Jumbotron>
          )}
          <TestimonialCard
            items={organization}
            headerLabel="Partners"
            testimonyKey="partner"
            firstTestimony={partner1}
          />
          {pictureOrDefined && (
            <CarouselImageSlide
              defaultSize
              imageOne={picture1}
              imageTwo={picture2}
              imageThree={picture3}
              imageFour={picture4}
              imageFive={picture5}
              testimonyOne={partner1}
            />
          )}
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
export default OrganizationPage;
