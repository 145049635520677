// importing 3rd party library
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import React, { useState, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Jumbotron, Container, FormControl } from "react-bootstrap";
// importing config, utils & services files
import { paginate } from "../../utils";
import { getQueries } from "../../services";
import { endPoints, styles, routes } from "../../config/";
// importing component files

import { PageNotFound } from "../";
import { ColorContext } from "../../context";
import { Count, Loader, SwitchButton } from "../../components";
import { BreadCrump, SearchInput, Pagination, Footer } from "../../components";
import { ToggleButtonGroup, EducationCardDeckElement } from "../../components";

/*  */
export default function SchoolSearch({ searchField, setSearchField }) {
  const { home } = routes;
  const labelOne = "Public";
  const labelTwo = "Private";
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { schoolsUrl, schoolCategoriesUrl } = endPoints;

  const [pageSize] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchMinValue, setSearchMinValue] = useState("");
  const [searchMaxValue, setSearchMaxValue] = useState("");
  const [value, setValue] = useState([labelOne, labelTwo]);
  const [searchCityField, setSearchCityField] = useState("");
  const [selectedCatagories, setSelectedCatagories] = useState([]);
  const [searchAddressField, setSearchAddressField] = useState("");
  const [searchCountryField, setSearchCountryField] = useState("");
  const [toggleAdvancedSearch, setToggleAdvancedSearch] = useState(false);

  const { data: schools, error } = useSWR(schoolsUrl, getQueries);
  const { data: educationCatagory } = useSWR(schoolCategoriesUrl, getQueries);

  if (error) return <PageNotFound text="Failed to get schools" />;
  if (!schools) return <Loader />;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const breadCrumbItem = [
    { id: 1, label: "Home", path: home },
    { id: 2, label: "School Search" },
  ];

  const { categoryColumnStyle, categoryFormHolder } = styles();
  const { advancedSearchHolder, flexColumnAlignStartJustifyCenter } = styles(
    primary
  );

  const handleSearch = (query) => {
    setSearchField(query);
    setCurrentPage(1);
  };

  const filterSchools = schools.filter((school) => {
    if (
      searchField &&
      !school.name.toLowerCase().includes(searchField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchAddressField &&
      !school.address.toLowerCase().includes(searchAddressField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchCountryField &&
      !school.country.toLowerCase().includes(searchCountryField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchCityField &&
      !school.city.toLowerCase().includes(searchCityField.toLowerCase())
    ) {
      return false;
    }
    if (
      selectedCatagories.length > 0 &&
      !selectedCatagories.some((catagory) =>
        school.providedEducationServices.includes(catagory)
      )
    ) {
      return false;
    }
    if (
      value.length > 0 &&
      !value.some((types) => school.organizationType === types)
    ) {
      return false;
    }
    if (searchMinValue && Number(school.minFee) < Number(searchMinValue)) {
      return false;
    }
    if (searchMaxValue && Number(school.maxFee) > Number(searchMaxValue)) {
      return false;
    }
    return true;
  });

  const handleCheckCatagories = (e) => {
    if (e.target.checked) {
      setSelectedCatagories(selectedCatagories.concat(e.target.name));
    } else {
      setSelectedCatagories(
        selectedCatagories.filter((catagory) => e.target.name !== catagory)
      );
    }
  };

  const paginatedSchools = paginate(filterSchools, currentPage, pageSize);

  return (
    <>
      {schools ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <Jumbotron>
            <Container>
              <h3 className="mb-4 text-muted">Search for schools</h3>
              <Count item={filterSchools} label="school" />
              <SearchInput
                value={searchField}
                placeholder="search schools by name"
                handleChange={(e) => handleSearch(e.target.value)}
              />
              <SwitchButton handleChange={setToggleAdvancedSearch} />
              {toggleAdvancedSearch ? (
                <Container className="mb-3" style={advancedSearchHolder}>
                  <Row>
                    <Col xs={12} sm={12} md={4} lg={4} className="mt-4">
                      <p>School type</p>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="mt-4">
                      <p>School Location</p>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="mt-4">
                      <p>Education Categories</p>{" "}
                    </Col>
                  </Row>
                  <Row className="pt-2 pb-4">
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <ToggleButtonGroup
                        value={value}
                        setValue={setValue}
                        labelOne={labelOne}
                        labelTwo={labelTwo}
                      />
                      {/*                   {value.find((el) => el === labelTwo) ? (
                       */}{" "}
                      <Form>
                        <Col style={flexColumnAlignStartJustifyCenter}>
                          min:{" "}
                          <FormControl
                            value={searchMinValue}
                            aria-label="minimum fee"
                            placeholder="minimum fee"
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            onChange={(e) => setSearchMinValue(e.target.value)}
                          />
                          max:{" "}
                          <FormControl
                            value={searchMaxValue}
                            aria-label="maximum fee"
                            placeholder="maximum fee"
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            onChange={(e) => setSearchMaxValue(e.target.value)}
                          />
                        </Col>
                      </Form>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <Form>
                        <Col style={flexColumnAlignStartJustifyCenter}>
                          Address:{" "}
                          <FormControl
                            value={searchAddressField}
                            aria-label="search by address"
                            placeholder="search by address"
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            onChange={(e) =>
                              setSearchAddressField(e.target.value)
                            }
                          />
                          City:{" "}
                          <FormControl
                            value={searchCityField}
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            aria-label="search by city name"
                            placeholder="search by city name"
                            onChange={(e) => setSearchCityField(e.target.value)}
                          />
                          Country:{" "}
                          <FormControl
                            value={searchCountryField}
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            aria-label="search by country name"
                            placeholder="search by country name"
                            onChange={(e) =>
                              setSearchCountryField(e.target.value)
                            }
                          />
                        </Col>
                      </Form>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      style={categoryColumnStyle}
                    >
                      <Col style={flexColumnAlignStartJustifyCenter}>
                        {educationCatagory.providedEducationServices.map(
                          ({ id, label }) => (
                            <div key={id} style={categoryFormHolder}>
                              <Form.Check
                                name={label}
                                type="checkbox"
                                id={`default-${label}`}
                                onClick={handleCheckCatagories}
                              />
                              <span>{label}</span>
                            </div>
                          )
                        )}
                      </Col>
                    </Col>
                  </Row>
                </Container>
              ) : null}
              <EducationCardDeckElement data={paginatedSchools} />
            </Container>
            <Container className="text-center mt-5">
              <Pagination
                pageSize={pageSize}
                onNextPage={onNextPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onPreviousPage={onPreviousPage}
                itemsCount={filterSchools.length}
              />
            </Container>
          </Jumbotron>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
// const currencySelect = React.useRef();
// const { queries: currency } = useQueries("educations/options");
// const selectCurrency = () => {
//   var value = currencySelect.current.value;
//   setCurrency(value);
// };
// {/* <Col>
//                           currency:
//                           {educationCatagory ? (
//                             <Form.Control
//                               as="select"
//                               className="mt-2"
//                               ref={currencySelect}
//                               onChange={selectCurrency}
//                             >
//                               {educationCatagory.currencies.map((sele) => (
//                                 <option key={sele.id} value={sele.id}>
//                                   {sele.label}
//                                 </option>
//                               ))}
//                             </Form.Control>
//                           ) : null}
//                         </Col> */}
