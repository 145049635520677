import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import { WhatsappIcon, LinkedinShareButton } from "react-share";
import { TwitterShareButton } from "react-share";

export const ShareButtons = ({ url, title, hashtag, hashtags }) => {
  return (
    <div className="mt-2">
      <FacebookShareButton url={url} quote={title} hashtag={hashtag}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} hashtags={hashtags}>
        <TwitterIcon size={40} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} />
      </WhatsappShareButton>
      <LinkedinShareButton url={url} source={url} title={title}>
        <LinkedinIcon size={40} />
      </LinkedinShareButton>
    </div>
  );
};
