// imporing 3rd party libraries
import { useContext } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
// imporing config files
import { styles } from "../../config/";
import { ColorContext } from "../../context";

function TogglesButtonGroup({ value, setValue, labelOne, labelTwo }) {
  const { primary } = useContext(ColorContext);
  const { primaryBorderWithBgBright } = styles(primary);
  const { colorBright, colorDark, primaryBorderWithBgPrimary } = styles(
    primary
  );
  return (
    <ToggleButtonGroup
      value={value}
      type="checkbox"
      className="mb-3"
      onChange={(e) => setValue(e)}
    >
      <ToggleButton
        className="mr-2"
        value={labelOne}
        style={
          value.find((el) => el === labelOne)
            ? primaryBorderWithBgPrimary
            : primaryBorderWithBgBright
        }
      >
        <span
          style={value.find((el) => el === labelOne) ? colorBright : colorDark}
        >
          {labelOne}
        </span>
      </ToggleButton>
      <ToggleButton
        value={labelTwo}
        style={
          value.find((el) => el === labelTwo)
            ? primaryBorderWithBgPrimary
            : primaryBorderWithBgBright
        }
      >
        <span
          style={value.find((el) => el === labelTwo) ? colorBright : colorDark}
        >
          {labelTwo}
        </span>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default TogglesButtonGroup;
