// imporing 3rd party library
import { Col, Form, FormControl } from "react-bootstrap";
// imporing config files
import { styles } from "../../config/";

function FormControllers({
  placeholderOne,
  placeholderTwo,
  labelOne,
  labelTwo,
}) {
  const { flexColumnAlignStartJustifyCenter } = styles();
  return (
    <Form>
      <Col style={flexColumnAlignStartJustifyCenter}>
        {labelOne}
        <FormControl
          className="ml-1 mr-1"
          aria-label={placeholderOne}
          placeholder={placeholderOne}
          aria-describedby="basic-addon2"
        />
        {labelTwo}
        <FormControl
          className="ml-1 mr-1"
          aria-label={placeholderTwo}
          placeholder={placeholderTwo}
          aria-describedby="basic-addon2"
        />
      </Col>
    </Form>
  );
}

export default FormControllers;
