// imporing 3rd party libraries
import { useContext } from "react";
import { Diagram2Fill, Diagram3Fill } from "react-bootstrap-icons";
import { DoorOpenFill, SuitClubFill } from "react-bootstrap-icons";
import { CpuFill, FileEarmarkPersonFill } from "react-bootstrap-icons";
import { FilePersonFill, PersonBadgeFill } from "react-bootstrap-icons";
import { Dice4Fill, LayersFill, CursorFill } from "react-bootstrap-icons";
import { BookmarkStarFill, BookmarkPlusFill } from "react-bootstrap-icons";
import { WalletFill, LaptopFill, CompassFill } from "react-bootstrap-icons";
import { BookmarkHeartFill, BookmarkCheckFill } from "react-bootstrap-icons";
import { Calendar2PlusFill, Calendar2WeekFill } from "react-bootstrap-icons";
import { HddRackFill, Grid1x2Fill, PaletteFill } from "react-bootstrap-icons";
import { BrightnessHighFill, Calendar3RangeFill } from "react-bootstrap-icons";
import { EmojiSunglassesFill, NutFill, AlignTop } from "react-bootstrap-icons";
import { BookFill, BookHalf, DiscFill, GearFill } from "react-bootstrap-icons";
import { ConeStriped, InboxesFill, BookmarkFill } from "react-bootstrap-icons";
// imporing context files
import { ColorContext } from "../context";

export function IconHolder({ el, index }) {
  const { primary } = useContext(ColorContext);
  return (
    <>
      {el === "Private" ? (
        <>
          <BookmarkPlusFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Public / Government" ? (
        <>
          <BookmarkFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "K-12 school" ? (
        <>
          <BookmarkHeartFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Kindergarten" ? (
        <>
          <Diagram2Fill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Primary Education" ? (
        <>
          <Diagram3Fill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Secondary Education" ? (
        <>
          <BookHalf
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Upper Secondary Education" ? (
        <>
          <BookmarkCheckFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "High School" ? (
        <>
          <BookmarkStarFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Maker Space" ? (
        <>
          <AlignTop
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Psychopedagogy Support" ? (
        <>
          <BrightnessHighFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Learning Management System" ? (
        <>
          <Calendar2WeekFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Education Management System" ? (
        <>
          <Calendar3RangeFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Education Apps" ? (
        <>
          <CompassFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Extracurricular activities" ? (
        <>
          <Dice4Fill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Special Needs Education" ? (
        <>
          <DiscFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Accessibility" ? (
        <>
          <DoorOpenFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Universal Design" ? (
        <>
          <GearFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Flipped Classroom" ? (
        <>
          <Grid1x2Fill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Hybrid Learning" ? (
        <>
          <HddRackFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Active Learning Methodologies" ? (
        <>
          <LayersFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Building Infrastructure" ? (
        <>
          <ConeStriped
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Internet Infrastructure" ? (
        <>
          <CursorFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Classroom Infrastructure" ? (
        <>
          <SuitClubFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Office Materials" ? (
        <>
          <InboxesFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Computer and Similar Devices" ? (
        <>
          <LaptopFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Book Publishers" ? (
        <>
          <BookFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Maker Space Infrastructure" ? (
        <>
          <AlignTop
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Learning Management System" ? (
        <>
          <BookmarkHeartFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Special Needs Educators" ? (
        <>
          <BookmarkStarFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Accessibility Infrastructure" ? (
        <>
          <DiscFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Flipped Classroom Trainers" ? (
        <>
          <Grid1x2Fill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Hybrid Learning Trainers" ? (
        <>
          <HddRackFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Active Learning Methodologies Trainers" ? (
        <>
          <LayersFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Subject Teachers" ? (
        <>
          <FileEarmarkPersonFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Extra Class Teachers" ? (
        <>
          <EmojiSunglassesFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Classroom Infrastructure Support" ? (
        <>
          <FilePersonFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Computer Lab" ? (
        <>
          <CpuFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Books" ? (
        <>
          <BookmarkFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Extra Class Teaching" ? (
        <>
          <NutFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Meeting place" ? (
        <>
          <Calendar2PlusFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Teacher Training" ? (
        <>
          <PersonBadgeFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Funding" ? (
        <>
          <WalletFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : el === "Other" ? (
        <>
          <PaletteFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      ) : (
        <>
          <PaletteFill
            className={index !== 0 ? "ml-2 mr-2" : "mr-2"}
            style={{
              color: primary,
            }}
          />
          {el}
        </>
      )}
    </>
  );
}
