// imporing 3rd party libraries
import { Dropdown } from "react-bootstrap";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ColorPicker, useColor } from "react-color-palette";
import { NavDropdown, Navbar, Nav, Button } from "react-bootstrap";
// imporing config files
import { openUrl } from "../utils/";
import { colorPallate } from "../data";
import { ColorContext } from "../context";
import { externalLink, styles, routes } from "../config/";
import "react-color-palette/lib/css/styles.css";

export default function Navbars({ setSearchField }) {
  const history = useHistory();
  const [color, setColor] = useColor(null);
  const [custom, setCustom] = useState(false);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(colorPallate[0].name);
  const { primary, setPrimary } = useContext(ColorContext);

  const { schoolSearch, organizationSearch } = routes;
  const { home, aboutPlatform, blogs, companySearch } = routes;

  const { cursorPointer } = styles();
  const { login, registerLink } = externalLink;
  //const { educationPartnership, civilOrganization ,educationInstitution} = externalLink
  return (
    <>
      <Navbar bg="light" expand="lg" style={{ zIndex: 4 }}>
        <Navbar.Brand onClick={() => setSearchField("")}>
          <strong style={cursorPointer} onClick={() => history.push(home)}>
            <strong>edupreneurs</strong>
          </strong>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="search for partners" id="basic-nav-dropdown-1">
              <NavDropdown.Item
                onClick={() => {
                  setSearchField("");
                  history.push(schoolSearch);
                }}
              >
                search for schools
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  setSearchField("");
                  history.push(companySearch);
                }}
              >
                search for companies
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  setSearchField("");
                  history.push(organizationSearch);
                }}
              >
                search for organizations
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              onClick={() => {
                openUrl(registerLink);
              }}
            >
              register here
            </Nav.Link>
            {/*   <NavDropdown
              title="register here"
              id="basic-nav-dropdown-2"
              onclick={() => {
                openUrl(registerLink)
              }}
            >
               <NavDropdown.Item
                onClick={() => {
                  openUrl(educationInstitution)
                }}
              >
                register as school
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  openUrl(educationPartnership)
                }}
              >
                register as company
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  openUrl(civilOrganization)
                }}
              >
                register as organization
              </NavDropdown.Item> 
            </NavDropdown>*/}
            <Nav.Link
              onClick={() => {
                setSearchField("");
                history.push(blogs);
              }}
            >
              blog
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setSearchField("");
                history.push(aboutPlatform);
              }}
            >
              about platform
            </Nav.Link>
          </Nav>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              style={{
                color: primary,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${primary}`,
              }}
            >
              <span>{!custom ? value : "custom"}</span>
              <div
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: "50%",
                  marginLeft: "0.4rem",
                  backgroundColor: primary,
                }}
              ></div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {colorPallate.map(({ name, hexCode }, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setValue(name);
                    setCustom(false);
                    setPrimary(hexCode);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "-1rem",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: "50%",
                        marginLeft: "0.5rem",
                        marginRight: "1rem",
                        backgroundColor: hexCode,
                      }}
                    ></div>
                    {name}
                  </div>
                </Dropdown.Item>
              ))}
              <Dropdown.Item
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                custom
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/*  <Nav.Link>
            <Button
              variant="outline-secondary"
              onClick={() => {
                openUrl(feedBack);
              }}
            >
              feedback
            </Button>
          </Nav.Link> */}
          <Nav.Link>
            <Button
              variant="outline-secondary"
              onClick={() => {
                openUrl(login);
              }}
            >
              login
            </Button>
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>
      {visible && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="pt-4">
            <ColorPicker
              width={456}
              height={228}
              color={color}
              onChange={(value) => {
                setCustom(true);
                if (visible && value) {
                  setPrimary(value.hex);
                  setColor(value);
                }
              }}
              hideHSV
              dark
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", padding: "1rem" }}
          >
            <Button
              variant="dark"
              className="m-1"
              onClick={() => {
                setVisible(false);
                setCustom(true);
              }}
            >
              save
            </Button>
            <Button
              onClick={() => {
                setPrimary(colorPallate[0].hexCode);
                setVisible(false);
                setCustom(false);
              }}
              variant="light"
              className="m-1"
            >
              cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
