// imporing 3rd party library
import { Col, Row } from "react-bootstrap";

function Count({ item, label, fromAllSearch }) {
  return (
    <>
      {fromAllSearch ? (
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="h5 ml-3 mb-3 text-muted">
              {item.length}{" "}
              {item.length > 1
                ? `${label} found`
                : "school, organization & company found"}
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="h5 ml-3 mb-3 text-muted">
              {item.length}{" "}
              {item.length > 1
                ? `${label !== "company" ? `${label}s` : "companies"} found`
                : `${label} found`}
            </p>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Count;
