const dark = "#000000";

export const colors = {
  darkHalfTransparent: `${dark}80`,
  lighterDarkerGrey: `${dark}0D`,
  jumboGreyDisloved: "#e9ecef33",
  blackTransparent: `${dark}1F`,
  darkTransparent: `${dark}4D`,
  darkDisolved: `${dark}4D`,
  blackDislove: `${dark}03`,
  defaultColor: "#e06f07",
  jacksonsBlue: "#06418D",
  sherpaBlue: "#013243",
  outerSpace: "#2e3131",
  jumboGrey: "#e9ecef",
  secondary: "#808080",
  niagara: "#239895",
  darkgrey:"#797E8D",
  falcon: "#765d69",
  bright: "#ffffff",
  seance: "#9a12b3",
  brick: "#96281b",
  happy: "#FF4848",
  grey: "#e5e4e1",
  pink: "#ff2c79",
  dark,
};
