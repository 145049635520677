// imporing 3rd party libraries
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BriefcaseFill } from "react-bootstrap-icons";
import { Card, Col, CardDeck } from "react-bootstrap";
// imporing config files
import { styles, endPoints, routes } from "../config";
import { openUrl, completeUrl, checkImage } from "../utils";
// importing component files
import { IconHolder, CardFooterHolder } from "./";
import { CardLabelHolder, CardImageHolder } from "./common/Containers";
import { ColorContext } from "../context";

function CompanyCardElement({ data }) {
  const history = useHistory();
  const { sourceImageUrl } = endPoints;
  const { companySearchById } = routes;
  const { primary } = useContext(ColorContext);
  const { cusorPointerWithColorPrimary } = styles(primary);
  const { cardImageStyle, cursorPointer } = styles(primary);
  const { fullHeight, marginTopDouble, flexAlignCenter } = styles();
  return (
    <CardDeck>
      {data.map((company, index) => {
        const { picture2, picture3, picture4, picture5 } = company;
        const { country, phoneNumber, providedEducationServices } = company;
        const { picture1, id, contactPerson, website, email, name } = company;
        const pictureAndUndefiend =
          !picture1 && !picture2 && !picture3 && !picture4 && !picture5;
        return (
          <Col sm={12} md={6} lg={6} key={index} className="mb-3">
            <Card style={fullHeight}>
              {pictureAndUndefiend ? (
                <CardImageHolder height="17.5rem">
                  <BriefcaseFill
                    width={50}
                    height={50}
                    style={cusorPointerWithColorPrimary}
                    onClick={() => history.push(companySearchById(id))}
                  />
                </CardImageHolder>
              ) : (
                <Card.Img
                  alt="image"
                  variant="top"
                  style={cardImageStyle}
                  src={checkImage(
                    sourceImageUrl,
                    picture1,
                    picture2,
                    picture3,
                    picture4,
                    picture5
                  )}
                  onClick={() => history.push(companySearchById(id))}
                />
              )}
              <Card.Body
                className="text-left"
                style={pictureAndUndefiend ? marginTopDouble : {}}
              >
                <h3 className="mb-4 text-muted">{name}</h3>
                {contactPerson && (
                  <CardLabelHolder
                    data={contactPerson}
                    label="Contact Person : "
                  />
                )}
                {country && (
                  <CardLabelHolder label="Country : " data={country} />
                )}
                {email && <CardLabelHolder label="Email : " data={email} />}
                {phoneNumber && (
                  <CardLabelHolder label="Phone : " data={phoneNumber} />
                )}
                {providedEducationServices.length > 0 && (
                  <CardLabelHolder
                    label="We Provide : "
                    data={providedEducationServices.map((label, index) => (
                      <span
                        key={index}
                        className="pb-1"
                        style={flexAlignCenter}
                      >
                        <IconHolder el={label} key={index} />
                      </span>
                    ))}
                  />
                )}
                {website && (
                  <CardLabelHolder
                    label="Website : "
                    data={
                      <span
                        style={cursorPointer}
                        className="text-primary"
                        onClick={() => {
                          openUrl(website);
                        }}
                      >
                        {completeUrl(website)}
                      </span>
                    }
                  />
                )}
              </Card.Body>
              <CardFooterHolder
                data={id}
                history={history}
                route="company-search"
                label="find more about the company"
              />
            </Card>
          </Col>
        );
      })}
    </CardDeck>
  );
}
export default CompanyCardElement;
