// imporing 3rd party library
import { useContext } from "react";
import { Search } from "react-bootstrap-icons";
import { Button, InputGroup } from "react-bootstrap";
// imporing config files
import { styles } from "../../config/";
// imporing component & context files
import { FormInput } from "../";
import { ColorContext } from "../../context";

function SearchInput({ placeholder, handleChange, value, ...props }) {
  const { primary } = useContext(ColorContext);
  const { outLineNoneWithBgPrimary, colorBright } = styles(primary);
  return (
    <InputGroup size="lg" className="mb-3">
      <FormInput
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        {...props}
      />
      <InputGroup.Prepend>
        <Button aria-label="search" variant="" style={outLineNoneWithBgPrimary}>
          <Search style={colorBright} />
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  );
}

export default SearchInput;
