// importing 3rd party library
import useSWR from "swr";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Jumbotron, Container, FormControl } from "react-bootstrap";
import { Form, Row, Col } from "react-bootstrap";
// importing config, utils & services files
import { paginate } from "../../utils/";
import { getQueries } from "../../services";
import { endPoints, styles, routes } from "../../config/";
// importing component files
import { PageNotFound } from "../";
import { Pagination, Footer, Loader } from "../../components/";
import { BreadCrump, Count, SearchInput } from "../../components/";
import { OrganizationCardDeckElement, SwitchButton } from "../../components/";
import { ColorContext } from "../../context";

export default function OrganizationSearch({ searchField, setSearchField }) {
  const { home } = routes;
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { organizationsUrl, organizationCategoriesUrl } = endPoints;

  const [pageSize] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCityField, setSearchCityField] = useState("");
  const [searchCountryField, setSearchCountryField] = useState("");
  const [searchAddressField, setSearchAddressField] = useState("");
  const [selectedCatagories, setSelectedCatagories] = useState([]);
  const [toggleAdvancedSearch, setToggleAdvancedSearch] = useState(false);

  const { data: organizations, error } = useSWR(organizationsUrl, getQueries);
  const { data: organizationCatagory } = useSWR(
    organizationCategoriesUrl,
    getQueries
  );

  if (error) return <PageNotFound text="Failed to get organizations" />;
  if (!organizations) return <Loader />;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleSearch = (query) => {
    setSearchField(query);
    setCurrentPage(1);
  };

  const breadCrumbItem = [
    { id: 1, label: "Home", path: home },
    { id: 2, label: "Organization Search" },
  ];

  const { categoryColumnStyle, categoryFormHolder } = styles();
  const { advancedSearchHolder, flexColumnAlignStartJustifyCenter } = styles(
    primary
  );

  const filterOrganizations = organizations.filter((organization) => {
    if (
      searchField &&
      !organization.name.toLowerCase().includes(searchField.toLowerCase())
    ) {
      return false;
    }
    if (
      selectedCatagories.length > 0 &&
      !selectedCatagories.some((catagory) =>
        organization.providedEducationServices.includes(catagory)
      )
    ) {
      return false;
    }
    if (
      searchAddressField &&
      !organization.address
        .toLowerCase()
        .includes(searchAddressField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchCityField &&
      !organization.city.toLowerCase().includes(searchCityField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchCountryField &&
      !organization.country
        .toLowerCase()
        .includes(searchCountryField.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const handleCheckCatagories = (e) => {
    if (e.target.checked) {
      setSelectedCatagories(selectedCatagories.concat(e.target.name));
    } else {
      setSelectedCatagories(
        selectedCatagories.filter((catagory) => e.target.name !== catagory)
      );
    }
  };

  const paginatedOrganizations = paginate(
    filterOrganizations,
    currentPage,
    pageSize
  );

  return (
    <>
      {organizations ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <Jumbotron>
            <Container>
              <h3 className="mb-4 text-muted">Search for organizations</h3>
              <Count item={filterOrganizations} label="organization" />
              <SearchInput
                value={searchField}
                placeholder="search organizations by name"
                handleChange={(e) => handleSearch(e.target.value)}
              />
              <SwitchButton handleChange={setToggleAdvancedSearch} />
              {toggleAdvancedSearch ? (
                <Container className="mb-3" style={advancedSearchHolder}>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="mt-4">
                      <p>Location</p>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="mt-4">
                      <p>Categories</p>{" "}
                    </Col>
                  </Row>
                  <Row className="pt-2 pb-4">
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Form>
                        <Col style={flexColumnAlignStartJustifyCenter}>
                          Address:{" "}
                          <FormControl
                            value={searchAddressField}
                            aria-label="search by address"
                            className="ml-1 mr-1 mb-2 mt-1"
                            placeholder="search by address"
                            aria-describedby="basic-addon2"
                            onChange={(e) =>
                              setSearchAddressField(e.target.value)
                            }
                          />
                          City:{" "}
                          <FormControl
                            value={searchCityField}
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            aria-label="search by city name"
                            placeholder="search by city name"
                            onChange={(e) => setSearchCityField(e.target.value)}
                          />
                          Country:{" "}
                          <FormControl
                            value={searchCountryField}
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            aria-label="search by country name"
                            placeholder="search by country name"
                            onChange={(e) =>
                              setSearchCountryField(e.target.value)
                            }
                          />
                        </Col>
                      </Form>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={categoryColumnStyle}
                    >
                      {organizationCatagory.providedEducationServices.map(
                        ({ id, label }) => (
                          <div key={id} style={categoryFormHolder}>
                            <Form.Check
                              type="checkbox"
                              id={`default-${label}`}
                              name={label}
                              onClick={handleCheckCatagories}
                            />
                            <span>{label}</span>
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </Container>
              ) : null}
              <OrganizationCardDeckElement data={paginatedOrganizations} />
            </Container>
            <Container className="text-center mt-5">
              <Pagination
                pageSize={pageSize}
                onNextPage={onNextPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onPreviousPage={onPreviousPage}
                itemsCount={filterOrganizations.length}
              />
            </Container>
          </Jumbotron>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
