export const checkImage = (
  url,
  itemOne,
  itemTwo,
  itemThree,
  itemFour,
  itemFive
) => {
  return itemOne
    ? url(itemOne)
    : itemTwo
    ? url(itemTwo)
    : itemThree
    ? url(itemThree)
    : itemFour
    ? url(itemFour)
    : itemFive
    ? url(itemFive)
    : null;
};
