// importing 3rd party library
import useSWR from "swr";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BookHalf } from "react-bootstrap-icons";
import { Card, CardDeck, Col } from "react-bootstrap";
import { Container, Jumbotron, Row } from "react-bootstrap";
// importing config, utils & services files
import { getCombinedQueries } from "../../services";
import { endPoints, styles, routes } from "../../config/";
import { completeUrl, openUrl, paginate, checkImage } from "../../utils";
// importing component files
import { PageNotFound } from "../";
import { ColorContext } from "../../context";
import { BreadCrump, SearchInput, Pagination } from "../../components";
import { CardFooterHolder, CardImageHolder, Footer } from "../../components";
import { CardLabelHolder, Count, IconHolder, Loader } from "../../components";

function AllFieldEduprenuer({ searchField, setSearchField }) {
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { home, ss, cs, os, setRoute } = routes;
  const { allQueryUrl, sourceImageUrl } = endPoints;
  const [pageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: allQuery, error } = useSWR(allQueryUrl, getCombinedQueries);

  if (error) return <PageNotFound text="Failed to get data" />;
  if (!allQuery) return <Loader />;

  const breadCrumbItem = [
    { id: 1, label: "Home", path: home },
    { id: 2, label: "All results" },
  ];

  const checkItemType = (item, valueOne, valueTwo, valueThree) => {
    return item.type === "education"
      ? valueOne
      : item.type === "company"
      ? valueTwo
      : item.type === "organization"
      ? valueThree
      : null;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleSearch = (query) => {
    setSearchField(query);
    setCurrentPage(1);
  };

  const filteredItems = allQuery.filter((item) => {
    if (
      searchField &&
      !item.name.toLowerCase().includes(searchField.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const paginatedItems = paginate(filteredItems, currentPage, pageSize);

  const { marginTopDouble, cursorPointer } = styles();
  const { fullHeight, cusorPointerWithColorPrimary, cardImageStyle } = styles(
    primary
  );
  return (
    <>
      {allQuery.length > 0 ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <Jumbotron>
            <Container>
              <h3 className="mb-4 text-muted">
                Search for schools, organizations, companies
              </h3>
              <Count
                fromAllSearch
                item={filteredItems}
                label="schools, organizations, companies"
              />
              <SearchInput
                value={searchField}
                handleChange={(e) => handleSearch(e.target.value)}
                placeholder="search schools, organization, companies by name"
              />{" "}
              <CardDeck>
                {paginatedItems.map((item, index) => {
                  const { picture1, picture2, picture3 } = item;
                  const { providedDiscountsOrScholarships } = item;
                  const { picture4, picture5, id, name, type } = item;
                  const { providedEducationServices, country } = item;
                  const { minFee, maxFee, currency, scholarship } = item;
                  const { website, contactPerson, email, phoneNumber } = item;
                  const pictureAndUndefined =
                    !picture1 &&
                    !picture2 &&
                    !picture3 &&
                    !picture4 &&
                    !picture5;
                  return (
                    <Col sm={12} md={12} lg={6} key={index} className="mb-3">
                      <Card style={fullHeight}>
                        {pictureAndUndefined ? (
                          <CardImageHolder height="17.5rem">
                            <BookHalf
                              style={cusorPointerWithColorPrimary}
                              width={50}
                              height={50}
                              onClick={() =>
                                history.push(
                                  setRoute(checkItemType(item, ss, cs, os), id)
                                )
                              }
                            />
                          </CardImageHolder>
                        ) : (
                          <Card.Img
                            alt="image"
                            variant="top"
                            style={cardImageStyle}
                            src={checkImage(
                              sourceImageUrl,
                              picture1,
                              picture2,
                              picture3,
                              picture4,
                              picture5
                            )}
                            onClick={() =>
                              history.push(
                                setRoute(checkItemType(item, ss, cs, os), id)
                              )
                            }
                          />
                        )}
                        <Card.Body
                          className="text-left"
                          style={pictureAndUndefined ? marginTopDouble : {}}
                        >
                          <h3 className="text-muted">{name}</h3>
                          <p className="mb-4 text-muted">
                            <i>type - {type}</i>
                          </p>
                          <Row>
                            <CardLabelHolder
                              isSearch
                              label="Website"
                              data={
                                website !== "" ? (
                                  <span
                                    className="text-primary"
                                    style={cursorPointer}
                                    onClick={() => {
                                      openUrl(website);
                                    }}
                                  >
                                    {completeUrl(website)}
                                  </span>
                                ) : (
                                  <span>Not given</span>
                                )
                              }
                            />
                            <CardLabelHolder
                              isSearch
                              label="Contact Person"
                              data={contactPerson}
                            />
                            <CardLabelHolder
                              isSearch
                              label="Email"
                              data={email}
                            />
                            <CardLabelHolder
                              isSearch
                              label="Phone"
                              data={
                                phoneNumber !== "" ? phoneNumber : "Not Given"
                              }
                            />
                            {minFee && (
                              <CardLabelHolder
                                isSearch
                                label="School Fee"
                                data={
                                  <>
                                    {minFee} {currency} - {maxFee} {currency}
                                  </>
                                }
                              />
                            )}
                            {providedDiscountsOrScholarships && (
                              <CardLabelHolder
                                isSearch
                                label="Scholarships"
                                data={scholarship}
                              />
                            )}
                          </Row>
                          <Row>
                            <CardLabelHolder
                              isSearch
                              label={checkItemType(
                                item,
                                "Provided Education Services",
                                "We Provide",
                                "We Provide"
                              )}
                              data={providedEducationServices.map(
                                (el, index) => (
                                  <span className="text-muted" key={index}>
                                    <IconHolder el={el} index={index} />
                                  </span>
                                )
                              )}
                            />
                          </Row>
                          <Row>
                            <CardLabelHolder
                              isSearch
                              label="Country"
                              data={country}
                            />
                          </Row>
                        </Card.Body>
                        <CardFooterHolder
                          data={id}
                          history={history}
                          route={checkItemType(item, ss, cs, os)}
                          label={checkItemType(
                            item,
                            "find more about the school",
                            "find more about the company",
                            "find more about the organization"
                          )}
                        />
                      </Card>
                    </Col>
                  );
                })}
              </CardDeck>
            </Container>
            <Container className="pt-5">
              <Pagination
                pageSize={pageSize}
                onNextPage={onNextPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onPreviousPage={onPreviousPage}
                itemsCount={filteredItems.length}
              />
            </Container>
          </Jumbotron>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default AllFieldEduprenuer;
