export const routes = {
  home: "/",
  blogs: "/blogs",
  ss: "school-search",
  cs: "company-search",
  notFound: "/not-found",
  os: "organization-search",
  allResults: "/all-results",
  schoolSearch: "/school-search",
  companySearch: "/company-search",
  aboutPlatform: "/about-platform",
  blogsById: (blogId) => `/blogs/${blogId}`,
  setRoute: (value, id) => `/${value}/${id}`,
  organizationSearchById: (organizationId) =>
    `/organization-search/${organizationId}`,
  organizationSearch: "/organization-search",
  schoolSearchById: (schoolId) => `/school-search/${schoolId}`,
  companySearchById: (companyId) => `/company-search/${companyId}`,
};
