// imporing 3rd party libraries
import useSWR from "swr";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BookmarkStarFill } from "react-bootstrap-icons";
import { CardDeck, Card, Button } from "react-bootstrap";
// imporing config & services files
import { checkImage } from "../utils";
import { getQueries } from "../services";
import { styles, endPoints, routes } from "../config/";
// importing component files
import { CardImageHolder, CardDeckHolder } from "../components/";
import { ColorContext } from "../context";

export default function CardDecks({ setSearchField }) {
  const history = useHistory();
  const { blogsById } = routes;
  const { sourceImageUrl, latestBlogsUrl } = endPoints;
  const { primary } = useContext(ColorContext);
  const { colorBright, outLineNoneWithBgPrimary } = styles(primary);
  const { latestBlogImgStyle, cusorPointerWithColorPrimary } = styles(primary);

  const { data: latestBlogs, error } = useSWR(latestBlogsUrl, getQueries);
  if (error) return <CardDeckHolder header="Some error occured" />;
  if (!latestBlogs) return <CardDeckHolder header="0 blog found" />;
  return (
    <>
      {latestBlogs.length > 0 ? (
        <CardDeckHolder header="Selected Content">
          <CardDeck>
            {latestBlogs.map((blog, index) => {
              const { id, title, abstract } = blog;
              const { image1, image2, image3 } = blog;
              const imageOrDefined = image1 || image2 || image3;
              return (
                <Card key={index}>
                  {imageOrDefined ? (
                    <Card.Img
                      variant="top"
                      alt={blog.title}
                      className="mb-0"
                      style={latestBlogImgStyle}
                      onClick={() => {
                        history.push(blogsById(id));
                        setSearchField("");
                      }}
                      src={checkImage(sourceImageUrl, image1, image2, image3)}
                    />
                  ) : (
                    <CardImageHolder height="12.5rem" additionalStyle="mb-0">
                      <BookmarkStarFill
                        width={50}
                        height={50}
                        style={cusorPointerWithColorPrimary}
                        onClick={() => {
                          history.push(blogsById(id));
                          setSearchField("");
                        }}
                      />
                    </CardImageHolder>
                  )}
                  <Card.Body className="text-center">
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                      <small className="text-muted">
                        {abstract.length > 150 ? (
                          <>
                            {" "}
                            {abstract.substring(0, 150)}
                            {"...."}{" "}
                          </>
                        ) : (
                          <>{abstract}</>
                        )}
                      </small>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <div className="text-center">
                      <Button
                        variant=""
                        style={Object.assign(
                          {},
                          colorBright,
                          outLineNoneWithBgPrimary
                        )}
                        onClick={() => {
                          history.push(blogsById(id));
                          setSearchField("");
                        }}
                      >
                        find out more
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              );
            })}
          </CardDeck>
        </CardDeckHolder>
      ) : (
        <CardDeckHolder header="No blogs found" />
      )}
    </>
  );
}
