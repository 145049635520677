// imporing 3rd party libraries
import { useContext } from "react";
import { Form } from "react-bootstrap";
// imporing config files
import { styles } from "../../config/";
import { ColorContext } from "../../context";

function SwitchButton({ handleChange }) {
  const { primary } = useContext(ColorContext);
  const { colorPrimary } = styles(primary);
  return (
    <Form.Check
      type="switch"
      className="mb-3"
      id="custom-switch"
      style={colorPrimary}
      label="advanced search"
      onClick={(e) => handleChange(e.target.checked)}
    />
  );
}

export default SwitchButton;
