const BACKEND_URL = "api";

export const endPoints = {
  blogUrl: `/${BACKEND_URL}/blogs`,
  allQueryUrl: `/${BACKEND_URL}/?search=`,
  categoryUrl: (api) => `/${BACKEND_URL}/${api}`,
  latestBlogsUrl: `/${BACKEND_URL}/blogs/latest`,
  schoolsUrl: `/${BACKEND_URL}/educations?search=`,
  companiesUrl: `/${BACKEND_URL}/companies?search=`,
  blogByIdUrl: (id) => `/${BACKEND_URL}/blogs/${id}`,
  sourceUrl: (source) => `/${BACKEND_URL}/${source}`,
  sourceImageUrl: (source) => source,
  companyCategoriesUrl: `/${BACKEND_URL}/companies/options`,
  schoolCategoriesUrl: `/${BACKEND_URL}/educations/options`,
  companyByIdUrl: (id) => `/${BACKEND_URL}/companies/${id}`,
  schoolByIdUrl: (id) => `/${BACKEND_URL}/educations/${id}`,
  organizationsUrl: `/${BACKEND_URL}/organizations?search=`,
  organizationCategoriesUrl: `/${BACKEND_URL}/organizations/options`,
  organizationByIdUrl: (id) => `/${BACKEND_URL}/organizations/${id}`,
};
