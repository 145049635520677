// imporing 3rd party library
import { useContext } from "react";
import { Button, Card, Jumbotron, Container } from "react-bootstrap";
// imporing config & context files
import { styles } from "../../config/";
import { ColorContext } from "../../context";

export const CardImageHolder = ({ children, height, additionalStyle }) => {
  const { cardImageHolderStyle } = styles();
  return (
    <Jumbotron
      style={Object.assign({}, cardImageHolderStyle, { height })}
      className={additionalStyle}
      variant="top"
    >
      {children}
    </Jumbotron>
  );
};

export const CardLabelHolder = ({ label, data, isSearch, style }) => {
  const { primary } = useContext(ColorContext);
  const { colorPrimary, borderLeftStyle } = styles(primary);
  const { borderBottomStyle, colorPrimaryTransparent } = styles(primary);
  return (
    <>
      {isSearch ? (
        <div className="mb-3 pl-3 mr-5">
          <p className="h6" style={borderBottomStyle}>
            <b style={colorPrimary}>{label}</b>
          </p>
          <span className="text-muted" style={style}>
            {data}
          </span>{" "}
        </div>
      ) : (
        <div className="mb-3 pl-3" style={borderLeftStyle}>
          <p className="h6">
            <b style={colorPrimaryTransparent}>{label}</b>
          </p>
          <span className="text-muted" style={style}>
            {data}
          </span>{" "}
        </div>
      )}
    </>
  );
};

export const CardFooterHolder = ({ history, route, data, label }) => {
  const { primary } = useContext(ColorContext);
  const { footerTextButtonStyle } = styles(primary);
  return (
    <Card.Footer>
      <div className="text-center">
        <Button
          variant=""
          style={footerTextButtonStyle}
          onClick={() => history.push(`/${route}/${data}`)}
        >
          {label}
        </Button>
      </div>
    </Card.Footer>
  );
};

export const PaginationHolder = ({ children }) => (
  <nav className="row justify-content-center">
    <ul className="pagination d-flex flex-wrap">{children}</ul>
  </nav>
);

export const CardDeckHolder = ({ header, children }) => (
  <Container className="mb-5">
    <h5 className="text-center mb-4">{header}</h5>
    {children}
  </Container>
);
