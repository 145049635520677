// imporing 3rd party libraries
import useSWR from "swr";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Search } from "react-bootstrap-icons";
import { Dropdown, Container, InputGroup } from "react-bootstrap";
import { Image, Badge, Button, ListGroup } from "react-bootstrap";
import { FormControl, NavDropdown, DropdownButton } from "react-bootstrap";
import { BookHalf, BriefcaseFill, XDiamondFill } from "react-bootstrap-icons";
// imporing config & data files
import { fieldData } from "../data/";
import { styles, routes, endPoints, colors } from "../config/";
// importing servives & image files
import { getQueriesWithFiltering } from "../services";
import { getCombinedQueriesWithFiltering } from "../services";
import HomePagePicture from "../assets/Images/Grid-image2-min.jpg";
import { ColorContext } from "../context";

export default function Jumbotrons({
  setSearchField,
  setFieldLabel,
  searchField,
  fieldLabel,
}) {
  const history = useHistory();
  const { allResults } = routes;
  const { primary } = useContext(ColorContext);
  const { schoolSearch, companySearch, organizationSearch } = routes;
  const { cusorPointerWithColorPrimary } = styles(primary);
  const { outLineNoneWithBgPrimary, colorBright } = styles(primary);
  const { homeScreenImagePosiiton, homeScreenImageStyle } = styles();
  const { homeScreenHolder, bgPrimaryHalfTrasparent, fontSizeBasic } = styles(
    primary
  );

  const { dark, bright } = colors;
  const { schoolsUrl, companiesUrl, organizationsUrl, allQueryUrl } = endPoints;

  const { data: schools } = useSWR(
    fieldLabel === "educations" && searchField !== ""
      ? `${schoolsUrl}${searchField}`
      : null,
    getQueriesWithFiltering
  );

  const { data: companies } = useSWR(
    fieldLabel === "companies" && searchField !== ""
      ? `${companiesUrl}${searchField}`
      : null,
    getQueriesWithFiltering
  );

  const { data: allQueries } = useSWR(
    fieldLabel === "all fields" && searchField !== ""
      ? `${allQueryUrl}${searchField}`
      : null,
    getCombinedQueriesWithFiltering
  );

  const { data: organizations } = useSWR(
    fieldLabel === "organizations" && searchField !== ""
      ? `${organizationsUrl}${searchField}`
      : null,
    getQueriesWithFiltering
  );

  var timeout = 0;
  const handleSearch = (e) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSearchField(e.target.value);
    }, 300);
  };

  return (
    <>
      <div style={homeScreenImagePosiiton}>
        <Image
          src={HomePagePicture}
          alt="homepage-picture"
          style={homeScreenImageStyle}
        />
      </div>
      <Container>
        <div style={homeScreenHolder}>
          <Badge className="text-left p-3 mb-5" style={bgPrimaryHalfTrasparent}>
            <h5>Welcome to Edupreneurs Platform</h5>
            <br />
            <h6 style={fontSizeBasic}>We are in education for better</h6>
          </Badge>
          <InputGroup size="lg" className="mb-3" style={{ zIndex: 2 }}>
            <FormControl
              aria-describedby="basic-addon2"
              onChange={(e) => {
                handleSearch(e);
              }}
              aria-label="schools, companies, organization ..."
              placeholder="schools, companies, organization ..."
            />
            <DropdownButton
              variant="light"
              as={InputGroup.Append}
              title={fieldLabel === "educations" ? "schools" : fieldLabel}
              id="input-group-dropdown-2"
              className="border-left"
            >
              {fieldData.map((field) => (
                <div key={field.id}>
                  {field.id === 1 ? null : <NavDropdown.Divider />}
                  <Dropdown.Item
                    href="#"
                    onClick={() => setFieldLabel(field.label)}
                  >
                    {field.label === "educations" ? "schools" : field.label}
                  </Dropdown.Item>
                </div>
              ))}
            </DropdownButton>
            <InputGroup.Prepend>
              <Button
                variant=""
                aria-label="search"
                onClick={() => {
                  if (fieldLabel === "all fields") {
                    history.push(allResults);
                  }
                  if (fieldLabel === "educations") {
                    history.push(schoolSearch);
                  }
                  if (fieldLabel === "organizations") {
                    history.push(organizationSearch);
                  }
                  if (fieldLabel === "companies") {
                    history.push(companySearch);
                  }
                }}
                style={outLineNoneWithBgPrimary}
              >
                <Search style={colorBright} />
              </Button>
            </InputGroup.Prepend>
          </InputGroup>
          {searchField !== "" ? (
            <ListGroup
              variant="flush"
              style={{
                color: dark,
                position: "absolute",
                backgroundColor: bright,
              }}
            >
              {(allQueries || schools || companies || organizations) && (
                <>
                  <ListGroup.Item>
                    {fieldLabel === "all fields" ? (
                      <>
                        <strong className="pr-2">{allQueries.length}</strong>
                        {allQueries.length > 1 ? "items found" : "item found"}
                      </>
                    ) : fieldLabel === "educations" ? (
                      <>
                        <strong className="pr-2">{schools.length}</strong>
                        {schools.length > 1 ? "schools found" : "school found"}
                      </>
                    ) : fieldLabel === "companies" ? (
                      <>
                        <strong className="pr-2">{companies.length}</strong>
                        {companies.length > 1
                          ? "companies found"
                          : "company found"}
                      </>
                    ) : fieldLabel === "organizations" ? (
                      <>
                        <strong className="pr-2">{organizations.length}</strong>
                        {organizations.length > 1
                          ? "organizations found"
                          : "organization found"}
                      </>
                    ) : null}{" "}
                  </ListGroup.Item>
                  {(fieldLabel === "all fields"
                    ? allQueries
                    : fieldLabel === "educations"
                    ? schools
                    : fieldLabel === "companies"
                    ? companies
                    : fieldLabel === "organizations"
                    ? organizations
                    : null
                  )
                    .slice(0, 7)
                    .map((query, index) => (
                      <ListGroup.Item
                        key={index}
                        className="border-bottom"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (fieldLabel === "all fields") {
                            if (query.type === "education") {
                              history.push(`/school-search/${query.id}`);
                            } else if (query.type === "company") {
                              history.push(`/company-search/${query.id}`);
                            } else if (query.type === "organization") {
                              history.push(`/organization-search/${query.id}`);
                            }
                          } else if (fieldLabel === "educations") {
                            history.push(`/school-search/${query.id}`);
                          } else if (fieldLabel === "organizations") {
                            history.push(`/organization-search/${query.id}`);
                          } else if (fieldLabel === "companies") {
                            history.push(`/company-search/${query.id}`);
                          }
                        }}
                      >
                        {query.type === "company" && (
                          <BriefcaseFill
                            width={35}
                            height={35}
                            style={cusorPointerWithColorPrimary}
                          />
                        )}
                        {query.type === "education" && (
                          <BookHalf
                            width={35}
                            height={35}
                            style={cusorPointerWithColorPrimary}
                          />
                        )}
                        {query.type === "organization" && (
                          <XDiamondFill
                            width={35}
                            height={35}
                            style={cusorPointerWithColorPrimary}
                          />
                        )}
                        <strong className="pl-3">{query.name}</strong> |{" "}
                        {query.city}, {query.country}
                      </ListGroup.Item>
                    ))}
                </>
              )}
            </ListGroup>
          ) : null}
        </div>
      </Container>
    </>
  );
}
