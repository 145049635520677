import { useState, useEffect } from "react";

const useSetBreadCrump = (dataArr, item, value = "name") => {
  const [data, setData] = useState(dataArr);

  const onAddItem = () => {
    if (item) {
      let isValid = dataArr.some((el) => el.label === item[value]);
      if (!isValid) {
        let arr = [...dataArr];
        let obj = { id: dataArr.length + 1, label: item[value] };
        arr.push(obj);
        setData(arr);
      }
    }
  };

  useEffect(() => {
    onAddItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return { data };
};

export default useSetBreadCrump;
