// imporing 3rd party libraries
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BookHalf } from "react-bootstrap-icons";
import { Card, Row, Col, CardDeck } from "react-bootstrap";
// imporing config files
import { styles, endPoints, routes } from "../config";
import { openUrl, completeUrl, checkImage } from "../utils";
// importing component files
import { IconHolder, CardFooterHolder } from "./";
import { CardLabelHolder, CardImageHolder } from "./";
import { ColorContext } from "../context";

function EducationCardDeckElement({ data }) {
  const history = useHistory();
  const { sourceImageUrl } = endPoints;
  const { schoolSearchById } = routes;
  const { primary } = useContext(ColorContext);
  const { fullHeight, cusorPointerWithColorPrimary } = styles(primary);
  const { cardImageStyle, marginTopDouble, cursorPointer } = styles(primary);
  return (
    <CardDeck>
      {data.map((school, index) => {
        const { picture2, picture3, picture4, picture5 } = school;
        const { providedEducationServices, country, name } = school;
        const { picture1, id, website, contactPerson, email } = school;
        const { phoneNumber, minFee, maxFee, currency, scholarship } = school;
        const pictureAndUndefiend =
          !picture1 && !picture2 && !picture3 && !picture4 && !picture5;
        return (
          <Col sm={12} md={12} lg={12} key={index} className="mb-3">
            <Card style={fullHeight}>
              {pictureAndUndefiend ? (
                <CardImageHolder height="17.5rem">
                  <BookHalf
                    width={50}
                    height={50}
                    style={cusorPointerWithColorPrimary}
                    onClick={() => history.push(schoolSearchById(id))}
                  />
                </CardImageHolder>
              ) : (
                <Card.Img
                  alt="image"
                  variant="top"
                  style={cardImageStyle}
                  src={checkImage(
                    sourceImageUrl,
                    picture1,
                    picture2,
                    picture3,
                    picture4,
                    picture5
                  )}
                  onClick={() => history.push(schoolSearchById(id))}
                />
              )}
              <Card.Body
                className="text-left"
                style={pictureAndUndefiend ? marginTopDouble : {}}
              >
                <h3 className="mb-4 text-muted">{name}</h3>
                <Row>
                  {website && (
                    <CardLabelHolder
                      isSearch
                      label="Website"
                      data={
                        <span
                          style={cursorPointer}
                          className="text-primary"
                          onClick={() => {
                            openUrl(website);
                          }}
                        >
                          {completeUrl(website)}
                        </span>
                      }
                    />
                  )}
                  {contactPerson && (
                    <CardLabelHolder
                      isSearch
                      label="Contact Person"
                      data={contactPerson}
                    />
                  )}
                  {email && (
                    <CardLabelHolder isSearch label="Email" data={email} />
                  )}
                  <CardLabelHolder
                    isSearch
                    label="Phone"
                    data={phoneNumber !== "" ? phoneNumber : "Not Given"}
                  />
                  <CardLabelHolder
                    isSearch
                    label="School Fee"
                    data={
                      minFee !== "" ? (
                        <>
                          {minFee} {currency} - {maxFee} {currency}
                        </>
                      ) : (
                        "Not Given"
                      )
                    }
                  />
                  {scholarship && (
                    <CardLabelHolder
                      isSearch
                      data={scholarship}
                      label="Scholarship"
                    />
                  )}
                </Row>
                {providedEducationServices.length > 0 && (
                  <Row>
                    <CardLabelHolder
                      isSearch
                      label="Provided Education Services"
                      data={providedEducationServices.map((el, index) => (
                        <span className="text-muted" key={index}>
                          <IconHolder el={el} index={index} />
                        </span>
                      ))}
                    />
                  </Row>
                )}
                {country && (
                  <Row>
                    <CardLabelHolder isSearch label="Country" data={country} />
                  </Row>
                )}
              </Card.Body>
              <CardFooterHolder
                data={id}
                history={history}
                route="school-search"
                label="find more about the school"
              />
            </Card>
          </Col>
        );
      })}
    </CardDeck>
  );
}
export default EducationCardDeckElement;
