// imporing 3rd party library
import _ from "lodash";
import { useState, useContext } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
// imporing config & utlils files
import { styles } from "../../config/";
import { isANumber } from "../../utils/";
import { ColorContext } from "../../context";

const Pagination = (props) => {
  const { primary } = useContext(ColorContext);
  const {
    bgColorBrightWithColorPrimary,
    bgColorPrimaryWithColorBright,
    primaryBorderWithPrimaryColor,
    bgColorBrightWithColorSecondary,
  } = styles(primary);
  const [hover, setHover] = useState(false);
  const [valuePage, setValuePage] = useState(props.currentPage);

  const { itemsCount, pageSize, currentPage } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);

  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  return (
    <>
      <nav className="row justify-content-center">
        <ul className="pagination d-flex flex-wrap">
          {pages.length > 5 ? (
            <li className="page-item">
              <button
                disabled={currentPage === 1}
                className="page-link shadow-none"
                style={
                  currentPage !== 1
                    ? bgColorBrightWithColorPrimary
                    : bgColorBrightWithColorSecondary
                }
                onClick={() => props.onPreviousPage()}
              >
                previous
              </button>
            </li>
          ) : null}
          {pages.length <= 10 &&
            pages.map((page) => (
              <li key={page}>
                <button
                  className="page-link shadow-none"
                  style={
                    page === currentPage
                      ? bgColorPrimaryWithColorBright
                      : bgColorBrightWithColorPrimary
                  }
                  onClick={() => props.onPageChange(page)}
                >
                  {page}
                </button>
              </li>
            ))}
          {pages.length > 10 &&
            pages
              .filter((el) => el < 10)
              .map((page) => (
                <li key={page}>
                  <button
                    className="page-link shadow-none"
                    style={
                      page === currentPage
                        ? bgColorPrimaryWithColorBright
                        : bgColorBrightWithColorPrimary
                    }
                    onClick={() => props.onPageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
          {pages.length > 10 && (
            <li>
              <button
                className="page-link shadow-none"
                style={bgColorBrightWithColorPrimary}
              >
                {"..."}
              </button>
            </li>
          )}

          {pages.length > 10 &&
            pages
              .filter((el) => el > pages.length - 2)
              .map((page) => (
                <li key={page}>
                  <button
                    className="page-link shadow-none"
                    style={
                      page === currentPage
                        ? bgColorPrimaryWithColorBright
                        : bgColorBrightWithColorPrimary
                    }
                    onClick={() => props.onPageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
          {pages.length > 5 ? (
            <li className="page-item">
              <button
                disabled={currentPage === pages[pages.length - 1]}
                className="page-link shadow-none"
                style={
                  currentPage !== pages[pages.length - 1]
                    ? bgColorBrightWithColorPrimary
                    : bgColorBrightWithColorSecondary
                }
                onClick={() => props.onNextPage()}
              >
                next
              </button>
            </li>
          ) : null}
        </ul>
      </nav>
      {pages.length > 10 && (
        <nav className="row justify-content-center">
          <ul className="pagination d-flex flex-wrap">
            <li>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    style={bgColorPrimaryWithColorBright}
                  >
                    Page no. {currentPage}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="set page (numbers only)"
                  aria-label="set page (numbers only)"
                  value={valuePage}
                  onChange={(e) => {
                    if (isANumber(e.target.value)) {
                      setValuePage(e.target.value);
                    }
                  }}
                  aria-describedby="inputGroup-sizing-default"
                />
                <InputGroup.Append
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <Button
                    onClick={() => props.onPageChange(Number(valuePage))}
                    variant=""
                    style={
                      !hover
                        ? primaryBorderWithPrimaryColor
                        : bgColorPrimaryWithColorBright
                    }
                  >
                    Change
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Pagination;
