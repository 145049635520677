// imporing 3rd party libraries
import { Card, CardDeck, Container } from "react-bootstrap";

function TestimonialCard({ items, testimonyKey, firstTestimony, headerLabel }) {
  const testimonialNumber = [1, 2, 3, 4, 5];
  return (
    <>
      {firstTestimony && (
        <Container className="mb-5">
          <h3 className="text-center text-muted mb-4">{headerLabel}</h3>
          <CardDeck>
            <Container>
              {testimonialNumber.map((el) => (
                <div key={el}>
                  {(items[`${testimonyKey}${el}`].split(":")[0] ||
                    items[`${testimonyKey}${el}`].split(":")[1]) && (
                    <Card className="mb-3" key={el}>
                      <Card.Body>
                        <blockquote className="blockquote mb-0">
                          <p>{items[`${testimonyKey}${el}`].split(":")[1]}</p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">
                              {items[`${testimonyKey}${el}`].split(":")[0]}
                            </cite>
                          </footer>
                        </blockquote>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              ))}
            </Container>
          </CardDeck>
        </Container>
      )}
    </>
  );
}

export default TestimonialCard;
