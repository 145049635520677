export const externalLink = {
  addBlog: "https://forms.glowdom.com/lomakkeet/1454/lomake.html",
  educationInstitution:
    "https://forms.glowdom.com/lomakkeet/1433/lomakkeet.html",
  educationPartnership:
    "https://forms.glowdom.com/lomakkeet/1434/lomakkeet.html",
  civilOrganization: "https://forms.glowdom.com/lomakkeet/1435/lomake.html",
  linkedInOne: "https://www.linkedin.com/in/jannaten-nayem-855ba4b8/",
  linkedInTwo: "https://fi.linkedin.com/in/masood-ahmadi-167051170",
  feedBack: "https://forms.glowdom.com/lomakkeet/703/lomake.html",
  linkedInThree: "https://www.linkedin.com/in/rami-heinisuo-2089b01/",
  linkedInFour: "https://www.linkedin.com/in/sebulon-david-45915b37/",
  linkedInFive:
    "https://www.linkedin.com/in/hasekiel-johannes-economics-mba-02455428/",
  login: "https://dashboard.ahaa.glowdom.com/",
  registerLink: "https://dashboard.ahaa.glowdom.com/authentication/register",
  recommendationPaper: "https://forms.glowdom.com/lomakkeet/1636/lomaki.html",
};
