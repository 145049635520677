// imporing 3rd party library
import { Spinner } from "react-bootstrap";
// imporing config files
import { styles } from "../../config/";

function Loader() {
  const { positionTopCenter } = styles();
  return (
    <Spinner variant="warning" animation="border" style={positionTopCenter} />
  );
}

export default Loader;
