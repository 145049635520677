// imporing 3rd party library
import { Breadcrumb } from "react-bootstrap";
// imporing config files
import { styles } from "../../config/";

function BreadCrumb({ history, breadCrumbItem, setSearchField }) {
  const { fontSizeBasic, fontSizeBasicWithPointer } = styles();
  return (
    <Breadcrumb style={{ marginBottom: "-1rem" }}>
      <li style={{ listStyleType: "none" }}>
        {breadCrumbItem.map((item, index) => (
          <span
            className={
              index === breadCrumbItem.length - 1
                ? "text-muted"
                : "text-primary mr-2"
            }
            key={item.id}
            onClick={() => {
              setSearchField("");
              history.push(item.path);
            }}
            style={
              index === breadCrumbItem.length - 1
                ? fontSizeBasic
                : fontSizeBasicWithPointer
            }
          >
            {item.label} {index === breadCrumbItem.length - 1 ? "" : "/"}
          </span>
        ))}
      </li>
    </Breadcrumb>
  );
}

export default BreadCrumb;
