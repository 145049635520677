import { colors } from "../config";

const { defaultColor, happy, falcon } = colors;
const { pink, brick, niagara, sherpaBlue } = colors;
const { jacksonsBlue, seance, outerSpace } = colors;

export const colorPallate = [
  { name: "Default", hexCode: defaultColor },
  { name: "Happy", hexCode: happy },
  { name: "Falcon", hexCode: falcon },
  { name: "Pinky", hexCode: pink },
  { name: "Old Brick", hexCode: brick },
  { name: "Niagara", hexCode: niagara },
  { name: "Sherpa Blue", hexCode: sherpaBlue },
  { name: "Jacksons blue", hexCode: jacksonsBlue },
  { name: "Seance", hexCode: seance },
  { name: "Outer Space", hexCode: outerSpace },
];
