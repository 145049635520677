// importing 3rd party library
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Jumbotron } from "react-bootstrap";
// importing config & component files
import { styles } from "../config/";
import { Footer } from "../components";

function PageNotFound({ text }) {
  const history = useHistory();
  const { pageNotFoundContainer } = styles();
  return (
    <>
      <Jumbotron fluid style={pageNotFoundContainer}>
        <Container>
          <h1 className="text-muted mb-5">{text}</h1>
          <Button
            variant="outline-secondary"
            onClick={() => {
              history.push("/");
            }}
          >
            Go back to homepage
          </Button>{" "}
        </Container>
      </Jumbotron>
      <Footer />
    </>
  );
}

export default PageNotFound;
