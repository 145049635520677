// imporing 3rd party libraries
import { useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Book, Briefcase, People } from 'react-bootstrap-icons'
// imporing config files
import { openUrl } from '../utils/'
import { externalLink, styles } from '../config/'
import { ColorContext } from '../context'

export default function BodyTags() {
  const { primary } = useContext(ColorContext)
  const { registerLink } = externalLink
  //const { educationPartnership, educationInstitution } = externalLink
  const { cursorPointer, colorBright, bgPrimary, flexRowAlignEnd } = styles(
    primary,
  )
  return (
    <div style={bgPrimary}>
      <Container className="text-center">
        <Row style={colorBright} className="mb-3 pt-5">
          <Col xs={12} md={12} lg={12}>
            <h5>REGISTER NOW ON THE PLATFORM IF YOU ARE:</h5>
          </Col>
        </Row>
        <Row
          className="pb-5"
          style={Object.assign({}, flexRowAlignEnd, colorBright)}
        >
          <Col xs={12} md={4} lg={4}>
            <Book
              width={70 * 0.7}
              height={50}
              className="mb-4"
              style={cursorPointer}
              onClick={() => {
                openUrl(registerLink)
              }}
            />
            <h5
              style={cursorPointer}
              onClick={() => {
                openUrl(registerLink)
              }}
            >
              Educational Institution
            </h5>
          </Col>

          <Col xs={12} md={4} lg={4}>
            <Briefcase
              width={70 * 0.7}
              height={59}
              className="mb-4"
              style={cursorPointer}
              onClick={() => {
                openUrl(registerLink)
              }}
            />
            <h5
              style={cursorPointer}
              onClick={() => {
                openUrl(registerLink)
              }}
            >
              Company
            </h5>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <People
              width={70 * 0.7}
              height={59}
              className="mb-4"
              style={cursorPointer}
              onClick={() => {
                openUrl(registerLink)
              }}
            />
            <h5
              style={cursorPointer}
              onClick={() => {
                openUrl(registerLink)
              }}
            >
              Civil organization
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
