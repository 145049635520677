// imporing 3rd party library
import { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
// imporing config files
import { colors, styles } from "../../config/";

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisiblity] = useState(false);
  const { greyColor, lighterDarkerGrey } = colors;
  const { scrollToTopPosition } = styles();

  useEffect(() => {
    if (pageYOffset > 400) {
      setVisiblity(true);
    } else {
      setVisiblity(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ scrollToTopPosition });

  if (!visible) {
    return false;
  }

  return (
    <ScrollUpButton
      onClick={scrollToTop}
      style={{
        background: greyColor,
        boxShadow: `0px 2px 2px ${lighterDarkerGrey}`,
      }}
    />
  );
};

export default ScrollToTop;
