// importing 3rd party library
import useSWR from "swr";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, ListGroup, ListGroupItem } from "react-bootstrap";
// importing confing and services files
import { getQueries } from "../../services";
import { styles, endPoints } from "../../config/";
// importing component files
import { PageNotFound } from "../";
import { getDate } from "../../utils";
import { BreadCrump, ShareButtons } from "../../components";
import { Footer, Loader, CarouselImageSlide } from "../../components";
// importing hook files
import { ColorContext } from "../../context";
import { BlogBreadCrump } from "../../data";
import { useSetBreadCrump } from "../../hooks/";

export default function BlogPage({ blogId, setSearchField }) {
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { blogByIdUrl } = endPoints;
  const { bgColorPrimaryWithColorBright, preLineText } = styles(primary);

  const { data: blog, error } = useSWR(blogByIdUrl(blogId), getQueries);
  const { data: breadCrumbItem } = useSetBreadCrump(
    BlogBreadCrump,
    blog,
    "title"
  );

  if (error) return <PageNotFound text="Blog doesn't exist" />;
  if (!blog) return <Loader />;

  const blogOrDefined = blog.image1 || blog.image2 || blog.image3;
  return (
    <>
      <BreadCrump
        history={history}
        breadCrumbItem={breadCrumbItem}
        setSearchField={setSearchField}
      />
      <Card className="mb-3">
        <Card.Body className="p-5" style={bgColorPrimaryWithColorBright}>
          <Container>
            <h3>{blog.title}</h3>
            <i>
              authored by - {blog.authorName}, {blog.jobPosition},{" "}
              {blog.organization} <br /> published on {getDate(blog)}
            </i>
            {blog.subtitle && (
              <p className="mb-3 font-weight-lighter">{blog.subtitle}</p>
            )}
            <ShareButtons
              title={blog.title}
              hashtag="#edupreneurs"
              hashtags={["blogs", "edupreneurs", "glowdom"]}
              url={`https://edupreneurs.ahaa.glowdom.com/#/blogs/${blog.id}`}
              // url={window.location.href}
            />
          </Container>
        </Card.Body>
        <Container>
          <ListGroup className="list-group-flush">
            <ListGroupItem className="mt-2 mb-5">{blog.abstract}</ListGroupItem>
            {blogOrDefined && (
              <CarouselImageSlide
                defaultSize
                imageOne={blog.image1}
                imageTwo={blog.image2}
                imageThree={blog.image3}
              />
            )}
            <ListGroupItem style={preLineText}>{blog.content}</ListGroupItem>
          </ListGroup>
        </Container>
      </Card>
      <Footer />
    </>
  );
}
