// importing 3rd party library
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { Jumbotron, Container, FormControl } from "react-bootstrap";
// importing config, utils & services files
import { paginate } from "../../utils/";
import { getQueries } from "../../services";
import { endPoints, styles, routes } from "../../config/";
// importing component files
import { PageNotFound } from "../";
import { CompanyCardDeckElement } from "../../components/";
import { SwitchButton, Footer, Loader } from "../../components/";
import { BreadCrump, Count, SearchInput, Pagination } from "../../components/";
import { ColorContext } from "../../context";

export default function CompanySearch({ searchField, setSearchField }) {
  const { home } = routes;
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { companiesUrl, companyCategoriesUrl } = endPoints;
  const [pageSize] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCityField, setSearchCityField] = useState("");
  const [selectedCatagories, setSelectedCatagories] = useState([]);
  const [searchAddressField, setSearchAddressField] = useState("");
  const [searchCountryField, setSearchCountryField] = useState("");
  const [toggleAdvancedSearch, setToggleAdvancedSearch] = useState(false);

  const { categoryColumnStyle, categoryFormHolder } = styles();
  const { advancedSearchHolder, flexColumnAlignStartJustifyCenter } = styles(
    primary
  );

  const breadCrumbItem = [
    { id: 1, label: "Home", path: home },
    { id: 2, label: "Company Search" },
  ];

  const { data: companies, error } = useSWR(companiesUrl, getQueries);
  const { data: companyCatagory } = useSWR(companyCategoriesUrl, getQueries);

  if (error) return <PageNotFound text="Failed to get companies" />;
  if (!companies) return <Loader />;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleSearch = (query) => {
    setSearchField(query);
    setCurrentPage(1);
  };

  const filterCompanies = companies.filter((company) => {
    if (
      searchField &&
      !company.name.toLowerCase().includes(searchField.toLowerCase())
    ) {
      return false;
    }
    if (
      selectedCatagories.length > 0 &&
      !selectedCatagories.some((catagory) =>
        company.providedEducationServices.includes(catagory)
      )
    ) {
      return false;
    }
    if (
      searchAddressField &&
      !company.address.toLowerCase().includes(searchAddressField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchCityField &&
      !company.city.toLowerCase().includes(searchCityField.toLowerCase())
    ) {
      return false;
    }
    if (
      searchCountryField &&
      !company.country.toLowerCase().includes(searchCountryField.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const handleCheckCatagories = (e) => {
    if (e.target.checked) {
      setSelectedCatagories(selectedCatagories.concat(e.target.name));
    } else {
      setSelectedCatagories(
        selectedCatagories.filter((catagory) => e.target.name !== catagory)
      );
    }
  };
  const paginatedCompanies = paginate(filterCompanies, currentPage, pageSize);
  return (
    <>
      {companies ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <Jumbotron>
            <Container>
              <h3 className="mb-4 text-muted">Search for companies</h3>
              <Count item={filterCompanies} label="company" />
              <SearchInput
                value={searchField}
                placeholder="search company by name"
                handleChange={(e) => handleSearch(e.target.value)}
              />
              <SwitchButton handleChange={setToggleAdvancedSearch} />
              {toggleAdvancedSearch ? (
                <Container className="mb-3" style={advancedSearchHolder}>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="mt-4">
                      <p>Location</p>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="mt-4">
                      <p>Categories</p>{" "}
                    </Col>
                  </Row>
                  <Row className="pt-2 pb-4">
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Form>
                        <Col style={flexColumnAlignStartJustifyCenter}>
                          Address:{" "}
                          <FormControl
                            value={searchAddressField}
                            aria-label="search by address"
                            placeholder="search by address"
                            aria-describedby="basic-addon2"
                            className="ml-1 mr-1 mb-2 mt-1"
                            onChange={(e) =>
                              setSearchAddressField(e.target.value)
                            }
                          />
                          City:{" "}
                          <FormControl
                            value={searchCityField}
                            aria-describedby="basic-addon2"
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-label="search by city name"
                            placeholder="search by city name"
                            onChange={(e) => setSearchCityField(e.target.value)}
                          />
                          Country:{" "}
                          <FormControl
                            value={searchCountryField}
                            className="ml-1 mr-1 mb-2 mt-1"
                            aria-describedby="basic-addon2"
                            aria-label="search by country name"
                            placeholder="search by country name"
                            onChange={(e) =>
                              setSearchCountryField(e.target.value)
                            }
                          />
                        </Col>
                      </Form>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={categoryColumnStyle}
                    >
                      {companyCatagory.providedEducationServices.map(
                        ({ id, label }) => (
                          <div key={id} style={categoryFormHolder}>
                            <Form.Check
                              name={label}
                              type="checkbox"
                              id={`default-${label}`}
                              onClick={handleCheckCatagories}
                            />
                            <span>{label}</span>
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </Container>
              ) : null}
              <CompanyCardDeckElement data={paginatedCompanies} />
            </Container>
            <Container className="text-center mt-5">
              <Pagination
                pageSize={pageSize}
                onNextPage={onNextPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onPreviousPage={onPreviousPage}
                itemsCount={filterCompanies.length}
              />
            </Container>
          </Jumbotron>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
