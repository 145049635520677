import axios from "axios";

export const getQueries = async (url) => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch ({ response, message }) {
    if (response.status === 404) {
      window.location("/not-found");
    }
  }
};
