// importing 3rd party library
import useSWR from "swr";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Container, Jumbotron } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
// importing config, utils & services files
import { getQueries } from "../../services/";
import { SchoolBreadCrump } from "../../data/";
import { styles, endPoints } from "../../config/";
import { openUrl, completeUrl, detectUrl, checkImage } from "../../utils/";
// importing component files
import { PageNotFound } from "../";
import { CarouselImageSlide, WebsiteButton } from "../../components/";
import { CardLabelHolder, BreadCrump, LinkButton } from "../../components/";
import { Footer, IconHolder, Loader, TestimonialCard } from "../../components/";
// importing hook files
import { useSetBreadCrump } from "../../hooks/";
import { ColorContext } from "../../context";

function EducationPage({ schoolId, setSearchField }) {
  const history = useHistory();
  const { primary } = useContext(ColorContext);
  const { schoolByIdUrl, sourceImageUrl } = endPoints;

  const { data: school, error } = useSWR(schoolByIdUrl(schoolId), getQueries);
  const { data: breadCrumbItem } = useSetBreadCrump(SchoolBreadCrump, school);

  if (error) return <PageNotFound text="School doesn't exisit" />;
  if (!school) return <Loader />;

  const { name, logo, organizationType, minFee, maxFee } = school;
  const { picture1, picture2, picture3, picture4, picture5 } = school;
  const { educationLevel, coreAdvantage, customer1, videoLink } = school;
  const { phoneNumber, address, city, country, email, website } = school;
  const { educationProposal, teamDescription, missionStatement } = school;
  const { currency, scholarship, providedDiscountsOrScholarships } = school;
  const { providedEducationServices, demandedServices, contactPerson } = school;

  const modifiedVideoLink = detectUrl(videoLink);

  const pictureAndUndefiend =
    !picture1 && !picture2 && !picture3 && !picture4 && !picture5;
  const pictureOrDefined =
    picture1 || picture2 || picture3 || picture4 || picture5;

  const { flexRowAlignEnd } = styles(primary);
  const { flexRowAlignCenter, websiteColHolder } = styles(primary);
  const { bgPrimary, colorPrimary, colorSecondary } = styles(primary);
  const { cursorPointer, jumboGreyBg, colorBright } = styles(primary);
  const { cursorPointerOneThirdHeight, preLineText } = styles(primary);
  const { colorPrimaryTransparent, borderLeftStyle } = styles(primary);
  const { shadowBgStyle, jumboDivHolder, websiteBgImgStyle } = styles();
  const { bgJumboGreyDisovedFullHeight, brightTextWithShadow } = styles();

  return (
    <>
      {school ? (
        <>
          <BreadCrump
            history={history}
            breadCrumbItem={breadCrumbItem}
            setSearchField={setSearchField}
          />
          <div
            style={
              pictureAndUndefiend
                ? jumboGreyBg
                : Object.assign({}, websiteBgImgStyle, {
                    backgroundImage: `url(${checkImage(
                      sourceImageUrl,
                      picture1,
                      picture2,
                      picture3,
                      picture4,
                      picture5
                    )})`,
                  })
            }
          >
            <div style={pictureOrDefined ? shadowBgStyle : {}}>
              <Container className="pt-5" style={{ zIndex: "1" }}>
                <Jumbotron
                  className={pictureOrDefined ? "pt-5 pb-5 mb-0" : "mb-0 pt-5"}
                  style={pictureOrDefined ? bgJumboGreyDisovedFullHeight : {}}
                >
                  <Container className="m-0 p-0">
                    <h1
                      className="mb-5"
                      style={
                        pictureOrDefined ? brightTextWithShadow : colorSecondary
                      }
                    >
                      {name}
                    </h1>
                    <Container>
                      <Row>
                        <Col xs={12} md={6} lg={6} className="mb-2">
                          <div style={websiteColHolder}>
                            <div
                              className="mb-4 pb-2"
                              style={flexRowAlignCenter}
                            >
                              <h2
                                className="mr-4"
                                style={colorPrimaryTransparent}
                              >
                                School info
                              </h2>
                              {logo && (
                                <Card.Img
                                  alt="image"
                                  variant="top"
                                  src={sourceImageUrl(logo)}
                                  style={cursorPointerOneThirdHeight}
                                />
                              )}
                            </div>
                            {organizationType && (
                              <CardLabelHolder
                                label="school type"
                                data={organizationType}
                              />
                            )}
                            <CardLabelHolder
                              label="school fee"
                              data={
                                minFee !== "" ? (
                                  <>
                                    {minFee} {currency} - {maxFee} {currency}
                                  </>
                                ) : (
                                  "not given"
                                )
                              }
                            />
                            {scholarship === "Yes" && (
                              <CardLabelHolder
                                label="scholarship"
                                data={providedDiscountsOrScholarships}
                              />
                            )}
                            {providedEducationServices &&
                              providedEducationServices.length > 0 && (
                                <CardLabelHolder
                                  label="we provide"
                                  data={providedEducationServices.map(
                                    (el, index) => (
                                      <span className="text-muted" key={index}>
                                        <IconHolder el={el} index={index} />
                                      </span>
                                    )
                                  )}
                                />
                              )}
                            {demandedServices && demandedServices.length > 0 && (
                              <div
                                className="mb-3 pl-3"
                                style={borderLeftStyle}
                              >
                                <p className="h6">
                                  <b style={colorPrimaryTransparent}>
                                    we are in need of
                                  </b>
                                </p>
                                {demandedServices.length > 1 && (
                                  <CheckCircleFill
                                    className="mr-2"
                                    style={colorPrimary}
                                  />
                                )}
                                {demandedServices.map((el, index) => (
                                  <span className="text-muted" key={index}>
                                    {el}{" "}
                                    {demandedServices.length - 1 !== index && (
                                      <CheckCircleFill
                                        className="mr-2"
                                        style={colorPrimary}
                                      />
                                    )}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} className="mb-2">
                          <div style={websiteColHolder}>
                            <h2
                              className="mb-4 pb-2"
                              style={colorPrimaryTransparent}
                            >
                              Contact us
                            </h2>
                            {contactPerson && (
                              <CardLabelHolder
                                label="contact person "
                                data={contactPerson}
                              />
                            )}
                            <CardLabelHolder
                              label="phone "
                              data={
                                phoneNumber !== "" ? phoneNumber : "not given"
                              }
                            />
                            {(address || city || country) && (
                              <CardLabelHolder
                                label="address "
                                data={`${address}, ${city}, ${country}`}
                              />
                            )}
                            {email && (
                              <CardLabelHolder label="email " data={email} />
                            )}
                            {website && (
                              <CardLabelHolder
                                label="website"
                                data={
                                  <span
                                    className="text-primary"
                                    style={cursorPointer}
                                    onClick={() => {
                                      openUrl(website);
                                    }}
                                  >
                                    {completeUrl(website)}
                                  </span>
                                }
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Container>
                </Jumbotron>
              </Container>
            </div>
          </div>
          {educationProposal && (
            <div style={bgPrimary}>
              <Container className="text-center">
                <Row style={colorBright} className="mb-3 pt-5">
                  <Col xs={12} md={12} lg={12}>
                    <p className="h5">
                      <b>EDUCATIONAL PROPOSAL</b>
                    </p>
                  </Col>
                </Row>
                <Row className="pb-5" style={flexRowAlignEnd}>
                  <Col xs={12} md={12} lg={12}>
                    <p style={preLineText}>{educationProposal}</p>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {(modifiedVideoLink ||
            missionStatement ||
            teamDescription ||
            educationLevel ||
            coreAdvantage ||
            website) && (
            <Jumbotron>
              <Container>
                <div className="mb-3" style={jumboDivHolder}>
                  <Container>
                    <h2 className="mb-4 pb-2" style={colorPrimaryTransparent}>
                      About us
                    </h2>
                    {teamDescription && (
                      <CardLabelHolder
                        style={preLineText}
                        data={teamDescription}
                        label="team description "
                      />
                    )}
                    {missionStatement && (
                      <CardLabelHolder
                        style={preLineText}
                        label="our mission "
                        data={missionStatement}
                      />
                    )}
                    {educationLevel && (
                      <CardLabelHolder
                        style={preLineText}
                        data={educationLevel}
                        label="our educational Level "
                      />
                    )}
                    {coreAdvantage && (
                      <CardLabelHolder
                        style={preLineText}
                        data={coreAdvantage}
                        label="our educational advantages "
                      />
                    )}
                    {modifiedVideoLink && (
                      <CardLabelHolder
                        data={
                          <>
                            {modifiedVideoLink.map((link) => (
                              <LinkButton link={link} />
                            ))}
                          </>
                        }
                        label="check our youtube videos "
                      />
                    )}
                    {website && (
                      <CardLabelHolder
                        label="find more about the school"
                        data={<WebsiteButton website={website} />}
                      />
                    )}
                  </Container>
                </div>
              </Container>
            </Jumbotron>
          )}
          <TestimonialCard
            items={school}
            testimonyKey="customer"
            headerLabel="Testimonies"
            firstTestimony={customer1}
          />
          {pictureOrDefined && (
            <CarouselImageSlide
              imageOne={picture1}
              imageTwo={picture2}
              imageThree={picture3}
              imageFour={picture4}
              imageFive={picture5}
              testimonyOne={customer1}
            />
          )}
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default EducationPage;
